import { useEffect, useState } from "react"
import { useAuth } from "../../services/auth/useAuth";
import { useJob } from "../../services/auth/useJob";

export default function CrossOrigin() {
    let {user, law, TOApp, token} = useAuth();
    let {job} = useJob();

    useEffect(()=>{
        try{
            let dataParent = {token: token, user: user, law : law, app: TOApp, job: job}
            console.log(dataParent)
            setTimeout(()=>{
                window.parent.postMessage(JSON.stringify(dataParent), document.referrer);
            }, 1000)
        }catch(error){
            console.log(error)
        }
    }, [])

    return <></>;
}