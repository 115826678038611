import React, { useState, ChangeEvent, useEffect } from 'react';
import { ReactComponent as Pen } from '../../assets/editPhoto.svg';
import style from './Popin.module.scss'
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider, frFR } from '@mui/x-date-pickers';
import { Popin } from '../../../services/object/Popin';
import { dateJstoSqlDate } from '../../../services/tools/translateDate';

interface FormPopinProps {
  onChange: (property: string, value: string) => void;
  formPopins: Popin;
  errorMessages: Record<string, string>;
  isRowClicked: boolean;
}

export default function FormPopin({ formPopins, onChange, errorMessages, isRowClicked }: FormPopinProps) {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [localFormValues, setLocalFormValues] = useState({
    title: formPopins?.title ?? '',
    content: formPopins?.content ?? '',
    buttonText: formPopins?.buttonText ?? '',
    createdBy: formPopins?.createdBy ?? '',
  })

  useEffect(() => {
    if (formPopins) {
      setLocalFormValues({
        title: formPopins?.title ?? '',
        content: formPopins?.content ?? '',
        buttonText: formPopins?.buttonText ?? '',
        createdBy: formPopins?.createdBy ?? '',
      });
    }
  }, [formPopins]);

  const handleLocalChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLocalFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    onChange(name, value);
  }
  const getBase64 = (file: File, callback: (result: string) => void) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (typeof reader.result === "string")
        callback(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      getBase64(file, (result: string) => {
        onChange("imageUrl", result)
      })
    } else {
      setSelectedImage(null);
    }
  };

  const handleCircleClick = () => {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <>
      <div className={style.popform}>
        <div className={style.popPictureContent}>
          <div
            className={`${style.rectangleContainer} ${selectedImage ? style.active : ""}`}
            onClick={handleCircleClick}
          >
            {selectedImage ? (
              <img
                src={selectedImage}
                alt=""
                className={style.selectedImage}
              />
            ) : formPopins?.imageUrl ? (
              <img
                src={formPopins.imageUrl}
                alt=""
                className={style.selectedImage}
              />
            ) : null}
            <Pen />
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            id="fileInput"
            name='imageUrl'
            style={{
              display: 'none',
            }}
          />
          <TextField
            fullWidth
            sx={{ marginTop: '1rem' }}
            size="small"
            label="Titre"
            name="title"
            value={localFormValues.title}
            onChange={handleLocalChange}
            onBlur={handleBlur}
            required
            error={!!errorMessages.title}
            helperText={errorMessages.title}
          />
        </div>
        <div className={style.popPictureContent}>
          <div className={style.popcontainer}>
            <div className={style.popContent}>

              <TextField
                id="outlined-multiline-static"
                sx={{ marginTop: '1rem', listStyleType: 'circle' }}
                multiline
                rows={5.4}
                fullWidth
                label="Contenu de la Pop-in"
                name="content"
                placeholder='Veuillez saisir du texte'
                value={localFormValues.content}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                required
                error={!!errorMessages.content}
                helperText={errorMessages.content}
              />
            </div>
            <div className={style.popContent}>
              <TextField fullWidth
                size="small"
                sx={{ marginTop: '1rem' }}
                label="Texte du bouton"
                name="buttonText"
                value={localFormValues.buttonText}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                required
                error={!!errorMessages.buttonText}
                helperText={errorMessages.buttonText}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}
                adapterLocale="fr"
                localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <div className={style.textField}>
                    <DatePicker
                      slotProps={{ textField: { size: 'small' } }}
                      label="Date début"
                      disablePast
                      value={formPopins?.dateStart ? dayjs(formPopins.dateStart) : null}
                      onChange={(e) => onChange('dateStart', (e?.toISOString()) ? dateJstoSqlDate(new Date(e.toISOString())) : "")}
                    />
                    <DatePicker
                      slotProps={{ textField: { size: 'small' } }}
                      disablePast
                      label="Date fin"
                      value={formPopins?.dateEnd ? dayjs(formPopins.dateEnd) : null}
                      onChange={(e) => onChange('dateEnd', (e?.toISOString()) ? dateJstoSqlDate(new Date(e.toISOString())) : "")}
                    />
                  </div>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        </div>
      </div>
      {isRowClicked && (
        <div className={style.createur}>
          <TextField fullWidth
            size="small"
            label="Créateur initial"
            name="createdBy"
            value={localFormValues.createdBy}
            onChange={handleLocalChange}
            onBlur={handleBlur}
            disabled
          />
        </div>
      )}
    </>
  );
}

