import style from '../law.module.scss';
import { TextField } from '@mui/material';
import React from 'react';
import { Role } from '../../../../services/object/Role';
import ControllableStates from '../../../../components/input/ControllableStates';
import { App } from '../../../../services/object/App';


interface RolePopupChildrensProps {
  errorMessages: Record<string, string>, 
  formRefRole: React.RefObject<HTMLFormElement>, 
  role: Role| undefined, 
  TOapp: App[]| undefined, 
  handleFormRoleChange: (property: string, value: string) => void
}
  const rolePopupChildrens = ({ errorMessages, 
                                formRefRole, 
                                role, 
                                handleFormRoleChange,
                                TOapp
                              } :RolePopupChildrensProps) => {

         
  const onChangeApp = (appUid: string) => {
    handleFormRoleChange("appUid", appUid)
  };

  return ([
          <form className={style.uiform} ref={formRefRole}>
              <div className={style.contentText}>
                  <TextField fullWidth label="Nom"  
                      size="medium"
                      value={role?.name}
                      name="name"
                      onChange={(e) => handleFormRoleChange('name', e.target.value)} required
                      error={!!errorMessages.name}
                      helperText={errorMessages.name}/>
                  {(TOapp) ? 
                    <ControllableStates
                        Objet={TOapp.filter(app => (app.isJraApp)).map((app:App) => {return {uid:app.uid, name:app.name}})}
                        legend="Application liée"
                        onChange={onChangeApp}
                        selecteUid={role?.appUid ?? ""}
                        errorMessages={errorMessages}
                        />
                        : ""
                  }
              </div>
          </form>
  ])
}
export default rolePopupChildrens;