
export const formatWithLineBreaks = (text: string | undefined) => {
    if (!text) return null;
  
    return text.split('\n').map((line, index) => (
      <>
        {line}
        <br />
      </>
    ));
  };