import React, { useEffect, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Service_Api } from '../../../services/Api';
import { Salepoint } from '../../../services/object/Salepoint';
import { Button, Tab, Tabs } from '@mui/material';
import ModalFormComponent from '../../../components/modal/ModalFormComponent';
import { Univers } from '../../../services/object/Univers';
import { GridCallbackDetails, GridColDef, GridRenderCellParams, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import lawPopupChildrens from './law/LawPopupChildrens';
import appPopupChildrens from './app/AppPopupChildrens';
import rolePopupChildrens from './role/RolePopupChildrens';
import Alert, { AlertProps } from '../../../components/alert/Alert';
import { displayFormErrors } from '../../../services/tools/errorForm';
import Notification, { NotificationProps } from '../../../components/notification/Notification';
import { Law } from '../../../services/object/Law';
import { Job } from '../../../services/object/Job';
import { Role } from '../../../services/object/Role';
import { App } from '../../../services/object/App';
import style from './law.module.scss';
import LawTinyChildPopup from './law/LawTinyPopupChild';
import TinyModalComponent from '../../../components/tinyModal/TinyModalComponent';
import TableChart from '../../../components/tables/TableUser';
import { Video } from '../../../services/object/Video';
import AppTinyPopupChild from './app/AppTinyPopupChild';

function LawPage() {
    const [dataLaw, setDataLaw] = useState<{law : Law, job: Job[], role:Role[], app:App}[]>([]);
    const [dataRole, setDataRole] = useState<{role:Role, app:App}[]>([]);
    const [dataApp, setDataApp] = useState<{app : App, job: Job[]|null, salepoint:Salepoint[]|null}[]>([]);
    const [ToJob, setToJob] = useState<Job[]>([]);
    const [arrayRoleLabel, setArrayRoleLabel] = useState<string[]>([]);
    const [TOvideo, setTOvideo] = useState<Video[]>([]);
    const [ToRole, setTORole] = useState<Role[]>([]);
    const [ToRoleSelectedApp, setTORoleSelectedApp] = useState<Role[]>([]);
    const [exceptionNumber, setExceptionNumber] = useState<number>(0);
    const [TOSalepoint, setTOSalepoint] = useState<Salepoint[]>([]);
    const [TOUnivers, setTOUnivers] = useState<Univers[]>([]);
    const [TOapp, setTOApp] = useState<App[]>([]);
    const [arrayUniversLabel, setArrayUniversLabel] = useState<[string[]]>();
    const [jobLabel, setJobLabel] = useState<string[]>([]);
    const [arrayJobLabel, setArrayJobLabel] = useState<[string[]]>();
    const [roleLabel, setRoleLabel] = useState<string[]>([]);
    const [arraySalepointLabel, setArraySalepointLabel] = useState<[string[]]>();
    const [rows, setRows] = useState([{}]);
    const [modalOpenRole, setModalOpenRole] = useState(false);
    const [modalOpenApp, setModalOpenApp] = useState(false);
    const [modalOpenLaw, setModalOpenLaw] = useState(false);
    const [tinyModalLawOpen, setTinyModalLawOpen] = useState(false);
    const [tinyModalAppOpen, setTinyModalAppOpen] = useState(false);
    const [law, setLaw] = useState<Law>();
    const [app, setApp] = useState<App>();
    const [role, setRole] = useState<Role>();
    const [popupChildrenLaw, setPopupChildrenLaw] = useState<JSX.Element[]>();
    const [popupChildrenApp, setPopupChildrenApp] = useState<JSX.Element[]>();
    const [popupChildrenRole, setPopupChildrenRole] = useState<JSX.Element[]>();
    const [tinyPopupChild, setTinyPopupChil] = useState<JSX.Element>();
    const [displayAlert, setDisplayAlert] = useState<AlertProps>();
    const [displayNotif, setDisplayNotif] = useState<NotificationProps>();
    const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});
    const formRefRole = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
    const formRefLaw = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
    const formRefApp = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
    const [valueTabs, setValueTabs] = React.useState(0);
    const [columns, setColumns] = React.useState<GridColDef<any>[]>([]);
    const [loading, setLoading] = useState(false);
    const [max, setMax] = useState<number>(0);

    const Api = Service_Api();
    
    /* hydrate data onload */
    useEffect(() => {
        document.title = "MyJra - Gestion droits";

        fecthData()
    }, []);
  
    const fecthData = async() => {
        //get all salepoint (for row data)
        handleChangeTabs(null, 0);
        //get all univers (to hydrate popup's radio input)
        let response = await Api.get("job/");
        setToJob(response?.data.map(
            (job:Salepoint)=> job
        ))
        response = await Api.get("app/");
        setTOApp(response?.data.map(
            (app:App)=> app
        ))
        response = await Api.get("role/");
        setTORole(response?.data.map(
            ( objectRole :{role:Role, app:App})=> objectRole.role
        ))
        response = await Api.get("salepoint/");
        setTOSalepoint(response?.data)

        response = await Api.get("univers/");
        setTOUnivers(response?.data)
    }

    /* end hydrate data */

    //Create add data in row when const data change
    useEffect(() => {
        setLoading(true);
        if(valueTabs === 0){
            setRows((dataLaw && dataLaw?.map(
                (items : {law : Law, job: Job[], role:Role[], app:App}) => {
                    return  { 
                        id: items.law.uid,
                        'JRA_law.name': items.law.name,
                        'JRA_law.description': items.law.description,
                        'JRA_job.name': (items.job && items.job.length) ? items.job.map((job: Job) => job.name).join(', ') : "",
                        'JRA_role.name': (items.role && items.role.length) ? items.role.map((role: Role) => role.name).join(', ') : "",
                        'JRA_app.name': (items.app) ? items.app.name : ""
                    }
                }
            )) ?? []);
            setLoading(false);
        }
    }, [dataLaw]);
    useEffect(() => {
        setLoading(true);
        if(valueTabs === 1){
            setRows((dataApp && dataApp.map(
                (items : {app : App, job: Job[]|null, salepoint:Salepoint[]|null}) => {
                    return  { 
                        "id": items.app.uid,
                        "JRA_app.name": items.app.name,
                        "JRA_app.url": items.app.url,
                        "JRA_app.isJraApp": items.app.isJraApp,
                        'JRA_job.name': (items.job && items.job.length) ? items.job.map((job: Job) => job.name).join(', ') : "",
                        'JRA_salepoint.name': (items.salepoint && items.salepoint.length) ? items.salepoint.map((salepoint: Salepoint) => salepoint.name).join(', ') : "",
                    }
                }
            )) ?? []);
            setLoading(false);
        }
    }, [dataApp]);
    useEffect(() => {
        setLoading(true);
        if(valueTabs === 2){
            setRows((dataRole && dataRole.map(
                (roleObject : {role:Role, app:App}) => {
                    return  { 
                        "id": roleObject.role.uid,
                        "JRA_role.name": roleObject.role.name,
                        "JRA_app.name": roleObject.app.name,
                    }
                }
            )) ?? []);
            setLoading(false);
        }
    }, [dataRole]);

    const getLaw = async(lawUid:string) => {
        //get salepoint by uid
        const response = await Api.get("law/", "uid="+lawUid);
        //set current salepoint (to hydrate popup's input)
        setLaw({
            uid: response?.data.law.uid,
            name: response?.data.law.name,
            description: response?.data.law.description,
            appUid: response?.data.law.appUid,
            roleUid: response?.data.law.roleUid
        });
        setRoleLabel(response?.data.role?.map((role:Role)=>(role.name)) ?? []);
        setJobLabel(response?.data.job?.map((job:Job)=>(job.name)) ?? []);
        setModalOpenLaw(true);
    }
    const getApp = async(appUid:string) => {
        //get salepoint by uid
        let response = await Api.get("app/", "uid="+appUid);
        //set current salepoint (to hydrate popup's input)

        setApp({
            uid: response?.data.uid,
            name: response?.data.name,
            url: response?.data.url,
            photo: response?.data.photo,
            TMailSupport: response?.data.TMailSupport,
            isJraApp: response?.data.isJraApp
        });
        
        response = await Api.get("app/getAllJobSalepointAssoByApp", "appUid="+appUid);
        let allValueJob: [string[]]= [[]];
        let allValueSalepoint: [string[]]= [[]];
        if(response?.success){
            response?.data.map((Telem:{TjobUid : [string], TsalepointUid: [string]}, index:number)=>{
                let TJobName = ToJob.filter((job : Job) => { 
                    return Telem.TjobUid.includes(job.uid)
                }).map((job :Job) => {return job.name})
                if(index > 0){
                    allValueJob.push(TJobName)
                }else{
                    allValueJob = [TJobName]
                }
                let TSalepointName = TOSalepoint.filter((salepoint : Salepoint) => { 
                    return Telem.TsalepointUid.includes(salepoint.uid)
                }).map((salepoint :Salepoint) => {return salepoint.name})
                if(index > 0){
                    allValueSalepoint.push(TSalepointName)
                }else{
                    allValueSalepoint = [TSalepointName]
                }
                setExceptionNumber(index+1)
            })
            setArrayUniversLabel(undefined);
        }
        if(allValueSalepoint)
            setArraySalepointLabel(allValueSalepoint);
        if(allValueJob)
            setArrayJobLabel(allValueJob);
       setModalOpenApp(true);
       
       
       setTORoleSelectedApp(ToRole.filter((role:Role) => (role.appUid === appUid)))
    }
    const getRole = async(roleUid:string) => {
        //get salepoint by uid
        const response = await Api.get("role/", "uid="+roleUid);
        //set current salepoint (to hydrate popup's input)
        setRole({
            uid: response?.data.role.uid,
            name: response?.data.role.name,
            appUid: response?.data.role.appUid
       });
       setModalOpenRole(true);
    }
    
    //event click on row
    const onRowClick = (params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => {
        //get current row id (= current salepoint id)
        let uid = params.id as string
        //get current salepoint
        switch(valueTabs){
            case 0:
                getLaw(uid)
                break;
            case 1:
                getApp(uid)
                break;
            case 2:
                getRole(uid)
                break;
        }
    }
    //event click on card
    const onCardClick = (uid:string) => {
        //get current row id (= current salepoint id)
        //get current salepoint
        switch(valueTabs){
            case 0:
                getLaw(uid)
                break;
            case 1:
                getApp(uid)
                break;
            case 2:
                getRole(uid)
                break;
        }
    }
    //event click on icon delete
    const deleteLine = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, params: GridRenderCellParams, valueTabs : number) =>{
        //desable onRowClick event
        event.preventDefault();
        event.stopPropagation();
        setDisplayAlert({
            open : true,
            title : "Suppression",
            contentText : "Êtes-vous sûr de vouloir supprimer cet item ?",
            contentButtonSuccess : "Oui",
            contentButtonAbord : "Non",
            onSubmitSuccess:  async () => {
                //get current row id (= current salepoint id)
                let uid = params.id as string
                let response;
                let newData;
                let object = "Le droit";
                switch(valueTabs){
                    case 0:
                        response = await Api.del("law/", {uid : uid});
                        //reload table data
                        setDataLaw((newData) => newData.filter(dataObject => dataObject.law.uid !== uid))
                        object = "Le droit";
                        break;
                    case 1:
                        response = await Api.del("app/", {uid : uid});
                        //reload table data
                        setDataApp((newData) => newData.filter(dataObject => dataObject.app.uid !== uid))
                        setTOApp((newData) => newData.filter((app:App) => app.uid !== uid))
                        object = "L'app";
                        break;
                    case 2:
                        response = await Api.del("role/", {uid : uid});
                        //reload table data
                        setDataRole((newData) => newData.filter((objectRole:{role:Role, app:App}) => objectRole.role.uid !== uid))
                        setTORole((newData) => newData.filter((role:Role) => role.uid !== uid))
                        object = "Le rôle";
                        break;
                }
                if(response?.success){
                    setDisplayNotif({
                        open : true,
                        contentText : object+" a été supprimée",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                }
                setDisplayAlert(undefined)
            },
            onSubmitAbord:  () => (setDisplayAlert(undefined))
        })
        
    }

    /* popup */
    const resetPopup = () => {
        setLaw(undefined)
        setApp(undefined)
        setRole(undefined)
        setJobLabel([])
        setRoleLabel([])
        setErrorMessages({})
        setArrayJobLabel([[]])
        setTOvideo([])
        setArrayUniversLabel([[]])
        setArraySalepointLabel([[]])
        setExceptionNumber(0)
    }
    /* popup */

    //reset children data when salepoint change
    useEffect(() => {
        let children = lawPopupChildrens({  errorMessages, 
                                            formRefLaw, 
                                            law, 
                                            ToJob,
                                            jobLabel,
                                            setJobLabel,
                                            handleFormLawChange,
                                            TOapp,
                                            ToRole,
                                            roleLabel,
                                            setRoleLabel});
        setPopupChildrenLaw(children)
    }, [law, jobLabel, roleLabel, TOapp, ToRole, errorMessages]);
    
    //reset children data when salepoint change
    useEffect(() => {

        let children = appPopupChildrens({  errorMessages, 
                                            formRefApp, 
                                            app,  
                                            ToRole:ToRoleSelectedApp,
                                            TOvideo,
                                            setTOvideo,
                                            ToJob,
                                            arrayJobLabel, 
                                            TOSalepoint,
                                            arraySalepointLabel,
                                            setArraySalepointLabel,
                                            setArrayJobLabel,
                                            TOUnivers,
                                            arrayUniversLabel,
                                            setArrayUniversLabel,
                                            handleFormAppChange,
                                            exceptionNumber, 
                                            setExceptionNumber,
                                            handleOpenTinyModalApp,
                                            arrayRoleLabel,
                                            onVideoChange
                                        });
        setPopupChildrenApp(children)
    }, [app, ToJob, ToRoleSelectedApp, TOSalepoint, TOUnivers, TOvideo, setTOvideo, arrayJobLabel, arraySalepointLabel, arrayUniversLabel, arrayRoleLabel, exceptionNumber, errorMessages]);

    //reset children data when salepoint change
    useEffect(() => {
        let children = rolePopupChildrens({ errorMessages, 
                                            formRefRole, 
                                            role, 
                                            handleFormRoleChange,
                                            TOapp});
        setPopupChildrenRole(children)
    }, [role, TOapp, errorMessages]);

    //reset children data when salepoint change

    //event change on first form
    const handleFormLawChange = (property: string, value: string) => {
        //replace old property in current salepoint (input data)
        setLaw((lawValue : any) => ({
        ...lawValue,
        [property]: value,
      }));
    };
    //event change on first form
    const handleFormAppChange = (property: string, value: string) => {
        //replace old property in current salepoint (input data)
        setApp((appValue : any) => ({
        ...appValue,
        [property]: value,
      }));
    };
    //event change on first form
    const handleFormRoleChange = (property: string, value: string) => {
        //replace old property in current salepoint (input data)
        setRole((roleValue : any) => ({
        ...roleValue,
        [property]: value,
      }));
    };

    //event on click button create
    const handleOpenModalLaw = () => {
        setModalOpenLaw(true);
        setDisplayAlert(undefined);
        //reset input's data
    };
    //event on click button create
    const handleOpenModalRole = () => {
        setModalOpenRole(true);
        setDisplayAlert(undefined);
        //reset input's data
    };
    //event on click button create
    const handleOpenModalApp = () => {
        setModalOpenApp(true);
        setDisplayAlert(undefined);
        //reset input's data
    };
    
    //event on click icon close
    const handleCloseModalLaw = () => {
        setModalOpenLaw(false);
        //reset input's data
        resetPopup();
    };

    //event on click icon close
    const handleCloseModalRole = () => {
        setModalOpenRole(false);
        //reset input's data
        resetPopup();
    };

    //event on click icon close
    const handleCloseModalApp = () => {
        setModalOpenApp(false);
        //reset input's data
        resetPopup();
    };

    //event on click button create
    const handleOpenTinyModalLaw = (law:Law) => {
        let child = LawTinyChildPopup({handleButtonAddLawUser, handleCloseTinyModal:handleCloseTinyModalLaw, law});
        setTinyPopupChil(child)
        setTinyModalLawOpen(true);
        //reset input's data

    }
    //event on click icon close
    const handleCloseTinyModalLaw = () => {
        setTinyModalLawOpen(false);
        handleCloseModalLaw()
        //reset input's data
    };

    //event on click button create
    const handleOpenTinyModalApp = (ToRole:Role[], TOvideo : Video[]) => {
        let child = AppTinyPopupChild({handleButtonAddRoleVideo, ToRole, arrayRoleLabel, setArrayRoleLabel, TOvideo});
        setTinyPopupChil(child)
        setTinyModalAppOpen(true);
        //reset input's data

    }
    //event on click icon close
    const handleCloseTinyModalApp = () => {
        setTinyModalAppOpen(false);
        //reset input's data
    };

    const handleButtonAddLawUser = async (law :Law) => {
        if(law.uid){
            await Api.post("law/addAssoUserByJob", {lawUid: law.uid, TjobUid: ToJob.filter((job)=> jobLabel.includes(job.name)).map((job)=>job.uid)}) 
            await Api.post("law/addAssoUserByRole", {lawUid: law.uid, TroleUid: ToRole.filter((role)=> roleLabel.includes(role.name) && law.appUid === role.appUid).map((role)=>role.uid)}) 
            handleCloseTinyModalLaw()
        }
    }
    

    const onVideoChange = async (file :File, videoUid: string, title: string) => {
        if(file && videoUid.includes('-')){
            const formdata = new FormData();
            formdata.append("file", file as unknown as Blob);
            formdata.append("uid", videoUid);
            formdata.append("title", title);
            formdata.append("appUid", app?.uid ?? "");
            let response = await Api.post('video/', formdata, {file:true})
            let newToVideo = TOvideo.map((video:Video)=>((video.uid === videoUid) ? response?.data : video))
            setTOvideo(newToVideo)
        }else{
            
        }
    }

    const handleButtonAddRoleVideo = async (TOrole:Role[], videoUid : string) => {
        let error = false;
        TOrole.forEach(async (role:Role)=>{
            if(error)
                return;
            let response = await Api.post("video/addAsso", {roleUid: role.uid, videoUid: videoUid}) 
            if(!response?.success){
                error = true;
                setDisplayNotif({
                    open : true,
                    contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                    severity : "error",
                    handleClose:  ()=>(setDisplayNotif(undefined))
                })
            }
        })
        if(!error)
            setDisplayNotif({
                open : true,
                contentText : "Configuration enregistrée",
                severity : "success", 
                handleClose:  ()=>(setDisplayNotif(undefined))
            })
        handleCloseTinyModalApp()
    }
    //event on salepoint's form
    const formEventLaw = async ()=>{
        let error = displayFormErrors(formRefLaw);
        if(Object.keys(error).length === 0){
            //if current salepoint has uid it exist so put
            let newLaw: {
                law : Law
                job : Job[]
                role : Role[]
                app : App
            };

            if(law?.uid){
                await Api.post("law/addAssoJob", {lawUid: law.uid, TjobUid: ToJob.filter((job)=> jobLabel.includes(job.name)).map((job)=>job.uid)}) 
                await Api.post("law/addAssoRole", {lawUid: law.uid, TroleUid: ToRole.filter((role)=> roleLabel.includes(role.name) && law.appUid === role.appUid).map((role)=>role.uid)}) 
                
                let response = await Api.put("law/", law);

                if(response?.success){
                    newLaw = { 
                        law : response?.data.law,
                        job: response?.data.job,
                        role: response?.data.role,
                        app: response?.data.app
                    }
                    setDataLaw((oldData) => oldData.filter(objectLaw => ((objectLaw.law.uid === law.uid) ? newLaw : objectLaw)));
                    setDisplayNotif({
                        open : true,
                        contentText : "Le droit a bien été modifié",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    handleCloseModalLaw()
                    return true;
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    handleCloseModalLaw()
                    return false;
                }
                    //else create it
            }else{
                let response = await Api.post("law/", law);
                
                await Api.post("law/addAssoJob", {lawUid: response?.data.law.uid, TjobUid: ToJob.filter((job)=> jobLabel.includes(job.name)).map((job)=>job.uid)}) 
                await Api.post("law/addAssoRole", {lawUid: response?.data.law.uid, TroleUid: ToRole.filter((role)=> roleLabel.includes(role.name) && response?.data.law.appUid === role.appUid).map((role)=>role.uid)}) 
                
                if(response?.success){
                    newLaw = { 
                        law : response?.data.law,
                        job: ToJob.filter((job)=> jobLabel.includes(job.name)),
                        role: ToRole.filter((role)=> roleLabel.includes(role.name) && response?.data.law.appUid === role.appUid),
                        app: response?.data.app
                    }
                    //reload table data
                    setDataLaw((newData) => [...newData, newLaw]);
                    setLaw(newLaw.law)
                    handleOpenTinyModalLaw(newLaw.law)
                    setDisplayNotif({
                        open : true,
                        contentText : "Le droit a bien été créé",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    handleCloseModalLaw()
                    return true;
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    handleCloseModalLaw()
                    return false;
                }
            }
        }else{
            setErrorMessages(error);
            handleCloseModalLaw()
            return false;
        }
    }
    //event on salepoint's form
    const formEventApp = async ()=>{
        let error = displayFormErrors(formRefApp);
        if(Object.keys(error).length === 0){
            //if current salepoint has uid it exist so put
            if(app?.uid){
                let response = await Api.put("app/", app);
                if(response?.success){
                    setDataApp((oldData) => oldData.filter(dataObject => dataObject.app.uid === app.uid ? app : dataObject))
                    setTOApp((oldData) => oldData.filter(oldApp => (app.uid === oldApp.uid) ? app : oldApp));
                    if(exceptionNumber > 0){
                        Api.del("app/deleteAllAssoSalepointAndJob", {
                            appUid: app.uid
                        })}
                    console.log(arrayJobLabel)
                    console.log(arraySalepointLabel)
                    for (let index = 0; index < exceptionNumber; index++) {
                        let TJobUid = ToJob.filter((job : Job) => { 
                            return arrayJobLabel && arrayJobLabel[index].includes(job.name)
                        }).map((job :Job) => {return job.uid})
                        let TSalepointUid = TOSalepoint.filter((salepoint : Salepoint) => { 
                            return arraySalepointLabel && arraySalepointLabel[index].includes(salepoint.name)
                        }).map((job :Job) => {return job.uid})
                        Api.put("app/addAssoSalepointAndJob", {
                            TJobUid : TJobUid,
                            TSalepointUid : TSalepointUid,
                            appUid: app.uid
                        })
                    } 
                    return true;
                }else{
                    return false;
                }
            //else create it
            }else{
                let response = await Api.post("app/", app);
                if(response?.success){
                    let newApp =response?.data
                    setDataApp((newData) => [...newData, {app: newApp, salepoint:  null, job : null}]);
                    setApp(newApp)
                    setTOApp((newData) => [...newData, newApp])
                    for (let index = 0; index < exceptionNumber; index++) {
                        let TJobUid = ToJob.filter((job : Job) => { 
                            return arrayJobLabel && arrayJobLabel[index].includes(job.name) 
                        }).map((job :Job) => {return job.uid})
                        let TSalepointUid = TOSalepoint.filter((salepoint : Salepoint) => { 
                            return arraySalepointLabel && arraySalepointLabel[index].includes(salepoint.name)
                        }).map((job :Job) => {return job.uid})
                        Api.put("app/addAssoSalepointAndJob", {
                            TJobUid : TJobUid,
                            TSalepointUid : TSalepointUid,
                            appUid: response?.data.uid
                        })
                    }
                    return true;
                }else{
                    return false;
                }
            }
        }else{
            setErrorMessages(error);
            return false;
        }
    }

    const formEventSupport = async () => {
        let error = displayFormErrors(formRefApp);
        if(Object.keys(error).length === 0){
            if(app?.uid){
                let response = await Api.put("app/", app);
                if(response?.success){
                    if(app.isJraApp)
                        setTOvideo((await Api.get("video/getVideoByAppUid", "?appUid="+app.uid))?.data ?? [])
                    return true;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        }else{
            setErrorMessages(error);
            return false;
        }
    }

    const formEventVideo = async () =>{
        
        let error = displayFormErrors(formRefApp);
        if(Object.keys(error).length === 0){
            if(app?.uid){
                let response = await Api.put("app/", app);
                if(response?.success){
                    if(exceptionNumber > 0){
                        Api.del("video/", {
                            appUid: app.uid
                        })
                    }
                    for (let index = 0; index < exceptionNumber; index++) {
                        Api.post("video", {
                            appUid: app.uid
                        })
                    } 
                    setDisplayNotif({
                        open : true,
                        contentText : "L'app a bien été modifiée",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    handleCloseModalApp();
                    return true;
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    handleCloseModalApp();
                    return false;
                }
            }else{
                handleCloseModalApp();
                return false;
            }
        }else{
            setErrorMessages(error);
            return false;
        }
    }
    //event on salepoint's form
    const formEventRole = async ()=>{
        let error = displayFormErrors(formRefRole);
        if(Object.keys(error).length === 0){
            //if current salepoint has uid it exist so put
            if(role?.uid){
                let response = await Api.put("role/", role);
                if(response?.success){
                    setDataRole((oldData) => oldData.filter(oldRole => (role.uid === oldRole.role.uid) ? role : oldRole));
                    setTORole((newData) => newData.filter((oldRole:Role) => (role.uid === oldRole.uid) ? role : oldRole))
                    handleCloseModalRole()
                    
                    setDisplayNotif({
                        open : true,
                        contentText : "Le rôle a bien été modifié",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    handleCloseModalRole();
                    return true;
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    handleCloseModalRole();
                    return false;
                }
            //else create it
            }else{
                let response = await Api.post("role/", role);
                if(response?.success){
                    let newRole =response?.data
                    let object = {role:newRole as Role, app:TOapp.filter(app => (app.uid === newRole.appUid))[0]}
                    setDataRole((newData) => [...newData, object]);
                    setTORole((newData) => [...newData, newRole]);
                    setRole(newRole)
                    handleCloseModalRole()
                    
                    setDisplayNotif({
                        open : true,
                        contentText : "Le rôle a bien été créé",
                        severity : "success",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    handleCloseModalRole();
                    return true;
                }else{
                    setDisplayNotif({
                        open : true,
                        contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
                        severity : "error",
                        handleClose:  ()=>(setDisplayNotif(undefined))
                    })
                    handleCloseModalRole();
                    return false;
                }
            }
        }else{
            setErrorMessages(error);
            handleCloseModalRole();
            return false;
        }
    }

    const getAllAppForDataGrid = async (paginationModel: { page: number; pageSize: number;} = { page: 0, pageSize: 25}, sort: {} = {}, filter: {}= {}) => {
        await Api.post("app/getAllAppForDataGrid", {
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sort : sort,
            filter : filter
          }).then((response)=>{
            setDataApp(response?.data?.map(
                (app:App) => app
            ))
            setMax(response?.recordsTotal ?? 0)
        });
        setColumns([
            { field: "JRA_app.name", headerName: 'Nom', flex:1},
            { field: 'JRA_app.url', headerName: 'Url', flex: 1, minWidth: 90 },
            { field: "JRA_app.isJraApp", headerName: 'Est interne', flex:0.19, 
                renderCell: (params: GridRenderCellParams<any>) => (
                    (params.row['JRA_app.isJraApp']) ? "Oui" : "Non"
                ),
            },
            { field: 'JRA_job.name', headerName: 'Métiers', flex: 1, minWidth: 90 },
            { field: 'JRA_salepoint.name', headerName: 'Concessions', flex: 1, minWidth: 90 },
            { field: 'action', headerName: 'Action', flex:1, type: 'boolean', 
                renderCell: (params: GridRenderCellParams<any>) => (
                <>
                    <IconButton title={"Modifier"}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton title={"Supprimer"} onClick={(e) => deleteLine(e, params, 1)}>
                        <DeleteIcon/>
                    </IconButton>
                </>),
            },
        ]);
    }
    
    const getAllRoleForDataGrid = async (paginationModel: { page: number; pageSize: number;} = { page: 0, pageSize: 25}, sort: {} = {}, filter: {}= {}) => {
        
        await Api.post("role/getAllRoleForDataGrid", {
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sort : sort,
            filter : filter
          }).then((response)=>{
            setDataRole(response?.data?.map(
                (role:Role) => role
            ))
            setMax(response?.recordsTotal ?? 0)
        });
        setColumns([
            { field: "JRA_role.name", headerName: 'Nom', flex:1},
            { field: 'JRA_app.name', headerName: 'Application(s)', flex: 0.19, minWidth: 90 },
            { field: 'action', headerName: 'Action', flex:1, type: 'boolean', 
                renderCell: (params: GridRenderCellParams<any>) => (
                <>
                    <IconButton title={"Modifier"}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton title={"Supprimer"} onClick={(e) => deleteLine(e, params, 2)}>
                        <DeleteIcon/>
                    </IconButton>
                </>),
            },
        ]);
    }
    
    const getAllLawForDataGrid = async (paginationModel: { page: number; pageSize: number;} = { page: 0, pageSize: 25}, sort: {} = {}, filter: {}= {}) => {
        await Api.post("law/getAllLawForDataGrid", {
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sort : sort,
            filter : filter
          }).then((response)=>{
            setDataLaw(response?.data?.map(
                (app:App) => app
            ))
            setMax(response?.recordsTotal ?? 0)
        });
        setColumns([
            { field: "JRA_law.name", headerName: 'Nom', flex:1},
            { field: "JRA_law.description", headerName: 'Description', flex: 1},
            { field: 'JRA_job.name', headerName: 'Métiers', flex: 1, minWidth: 90 },
            { field: 'JRA_role.name', headerName: 'Rôles', flex:1, minWidth: 90 },
            { field: 'JRA_app.name', headerName: 'Application', flex: 1, minWidth: 90 },
            { field: 'action', headerName: 'Action', flex:1, type: 'boolean', 
                renderCell: (params: GridRenderCellParams<any>) => (
                <>
                    <IconButton title={"Modifier"}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton title={"Supprimer"} onClick={(e) => deleteLine(e, params, 0)}>
                        <DeleteIcon/>
                    </IconButton>
                </>),
            },
        ]);
    }

    const handleChangeTabs = (event: any, value: any) => {
        setValueTabs(value);
        switch(value){
            case 0:
                getAllLawForDataGrid();
                break;
            case 1:
                getAllAppForDataGrid();
                break;
            case 2:
                getAllRoleForDataGrid();
                break;
        }
    };

    
  const searchFields = [
    'JRA_app.name',
    'JRA_job.name',
    'JRA_role.name',
    'JRA_salepoint.name'
  ];

    return (
        <div className="background">

        <h1>Gérer les applications</h1>
          <div className="backsquare">
            <div className="tableContainer">
            <div className="lineContain">
                <Button  className='addbutton' 
                         variant="contained" 
                         sx={{width:230}} 
                         onClick={handleOpenModalApp} 
                         endIcon={<AddIcon/>}>
                    1- Créer une application
                </Button>
                <Button  className='addbutton' 
                         variant="contained" 
                         sx={{width:230}} 
                         onClick={handleOpenModalRole} 
                         endIcon={<AddIcon/>}>
                    2- Créer un rôle
                </Button>
                <Button  className='addbutton' 
                         variant="contained" 
                         sx={{width:230}} 
                         onClick={handleOpenModalLaw} 
                         endIcon={<AddIcon/>}>
                    3- Créer un droit
                </Button>
            </div>
            <Tabs className={style.tabsContainer} 
                    value={valueTabs} 
                    onChange={handleChangeTabs} 
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{ style: { display: 'none' } }}>
                <Tab className={(0 === valueTabs) ? style.selected : ""} label={"Les Droits"}/>
                <Tab className={(1 === valueTabs) ? style.selected : ""} label={"Les Apps"}/>
                <Tab className={(2 === valueTabs) ? style.selected : ""} label={"Les Rôles"}/>
            </Tabs>
            {
                (columns.length) ? 
                <TableChart
                    columns={columns} 
                    dataRow={rows} 
                    loading={loading}
                    callBackFunctionGetRowData={
                        valueTabs === 1 ? getAllAppForDataGrid
                        : valueTabs === 2 ? getAllRoleForDataGrid
                        : getAllLawForDataGrid
                    }
                    max={max}
                    searchFields={searchFields}
                    disableRowSelectionOnClick={1 === valueTabs}
                    onRowClick={onRowClick}
                    onCardClick={onCardClick}
                    handleDeleteRow={(e)=>(true)}
                    />
                : ""
            }
            <ModalFormComponent 
                title={"Créer un droit"}
                modalOpen={modalOpenLaw} 
                onClose={handleCloseModalLaw} 
                onFormSubmitSuccess={formEventLaw} 
                childrenForm={popupChildrenLaw}
                formEvent={[formEventLaw]}
                setErrorMessages={setErrorMessages}/>

            <ModalFormComponent 
                title={"Créer un rôle"}
                modalOpen={modalOpenRole} 
                onClose={handleCloseModalRole} 
                onFormSubmitSuccess={formEventRole} 
                childrenForm={popupChildrenRole}
                formEvent={[formEventRole]}
                setErrorMessages={setErrorMessages}/>

            <ModalFormComponent 
                title={"Créer une application"}
                modalOpen={modalOpenApp} 
                onClose={handleCloseModalApp} 
                onFormSubmitSuccess={formEventVideo} 
                childrenForm={popupChildrenApp}
                formEvent={[formEventApp, formEventSupport, formEventVideo]}
                setErrorMessages={setErrorMessages}/>

            <TinyModalComponent 
                    title={"Attribuer les droits"}
                    modalOpen={tinyModalLawOpen} 
                    onClose={handleCloseTinyModalLaw} 
                    child={tinyPopupChild}
                    progess={false}/>

            <TinyModalComponent 
                    title={"Lier la vidéo aux rôles"}
                    modalOpen={tinyModalAppOpen} 
                    onClose={handleCloseTinyModalApp} 
                    child={tinyPopupChild}
                    progess={false}/>
                
            {
                (displayAlert?.open === true) ? <Alert {...displayAlert}/>: ""
            }
            {
                (displayNotif?.open === true) ? <Notification {...displayNotif}/>: ""
            }
            </div>
          </div>
        </div>
      );
}


export default LawPage;