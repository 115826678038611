import style from "./help.module.scss";
import ControllableStates from "../../components/input/ControllableStates";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize'
import { useEffect, useRef, useState } from "react";
import { App } from "../../services/object/App";
import { useApp } from "../../services/auth/useApp";
import { TextField, Button, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from "@emotion/styled";
import Service_Api from "../../services/Api";
import { Video } from "../../services/object/Video";
import Notification, { NotificationProps } from "../../components/notification/Notification";
import SendIcon from '@mui/icons-material/Send';
import { displayFormErrors } from "../../services/tools/errorForm";
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import RuleIcon from '@mui/icons-material/Rule';

function Profile() {
  const { TOApp } = useApp();
  const [app, setApp] = useState<App|null>(
    { uid: "", name: "", url:"", photo:"", isJraApp:false, iconUrl:"" }
  );
  const [selectedVideo, setSelectedVideo] = useState<Video|null>();
  const [TOvideo, setTOvideo] = useState<Video[]|null>(null);
  const [displayNotif, setDisplayNotif] = useState<NotificationProps>();
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({});
  const formRef = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;

  const [mail, setMail] = useState<{name: string, content:string, file:FileList|null}>(
    {name: "", content:"", file:null}
  );

  const Api = Service_Api();

  const onChangeApp = (appUid: string) => {
    let app = TOApp.find((app:App)=> app.uid === appUid)
    if(app)
      setApp(app)
    else
      setApp(null)
  };

  const sendMail = async () => {
    let error = displayFormErrors(formRef);
    if(Object.keys(error).length !== 0){
        setErrorMessages(error);
        return false;
    }
    console.log(mail)
    const formdata = new FormData();
    if(mail.file && mail.file.length){
      for (let i = 0; i < mail.file.length; ++i) {
          const formdata = new FormData();
          formdata.append("file", mail.file[i] as unknown as Blob);
          await Api.post('app/supportFile', formdata, {file:true})
      }
  }

    Api.post("app/sendMail", {
      appUid: app?.uid,
      name: mail.name,
      content: mail.content
    }).then((response) => {
      console.log(response)
      if(response?.success)
        setDisplayNotif({
          open : true,
          contentText : "Votre demande d'assistance a bien été transmise. Vous serez bientôt recontacté.",
          severity : "success",
          handleClose:  ()=>(setDisplayNotif(undefined))
        })
      else
        setDisplayNotif({
          open : true,
          contentText : "Aïe, une erreur inconnue est apparue. Merci de réessayer plus tard. (code erreur: "+response?.messages.code+")",
          severity : "error",
          handleClose:  ()=>(setDisplayNotif(undefined))
        })

    })
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleFormMailChange = (property: string, value: string|FileList|null) => {
      setMail({...mail,
        [property]: value})
  }

  useEffect(()=>{
    if(app?.uid){
      Api.get("video/getVideoByUserAndAppUid", "?appUid="+app.uid).then((response) => {
        if(response?.success){
          setSelectedVideo(response?.data[0])
          setTOvideo(response?.data)
        }else{
          setSelectedVideo(null)
          setTOvideo(null)
        }
      })
    }
  }, [app]);

  return (
    <div className="background">
      <h1>Besoin d'aide ?</h1>
      <div className="backsquare">
        <form  className={style.uiform} ref={formRef}>
          <h3 className={style.breakTitle}>Vous rencontrez des difficultés sur certaines applications ?</h3>
          <ControllableStates
              Objet={TOApp}
              legend="Sélectionnez l'application concernée"
              onChange={onChangeApp}
              selecteUid={app?.uid ?? ""}
              errorMessages={{"": ""}}
              />
              {
                (TOvideo !== null && TOvideo.length) ?
                  <>
                    <h3 className={style.breakTitle}>Regardez les tutoriels vidéos concernant cette application</h3>
                    <div className={style.containerVideo}>
                      <div className={style.mainVideo}>
                        <div className={style.video}>
                          {
                            (selectedVideo) ? 
                            <>
                              <video controls src={selectedVideo.url}>
                              </video>
                              <h4 className={style.titleVideo}>{selectedVideo.title}</h4>
                            </>
                            :
                            ""
                          }
                          
                        </div>
                      </div>
                      <div className={style.videoList}>
                        {
                        TOvideo.map((video: Video) => (
                          <div className={ (video.uid === selectedVideo?.uid) ? style.vid + " " + style.active : style.vid} onClick={(e) => (setSelectedVideo(video))}>
                            <video src={video.url}>
                            </video>
                            <h4 className={style.titleVideo}>{video.title}</h4>
                          </div>
                        ))
                        }
                      </div>
                    </div>
                    <h3 className={style.breakTitle}>Ou sinon</h3>
                  </>
                  :
                    ""
              }
              {
              (app?.uid) ? 
                <>
                    <h3 className={style.breakTitle}>Dites nous tout</h3>
                    <p>Indiquez dans votre message :</p>
                    <List className={style.breakTitle}>
                        <ListItem>
                          <ListItemIcon>
                            <ChecklistRtlIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Ce que vous souhaitiez faire"
                          />
                        </ListItem>
                        <ListItem>
                        <ListItemIcon>
                            <RuleIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Ce qu'il s'est finalement passé"
                          />
                        </ListItem>
                    </List>
                    <TextField fullWidth label="Donnez un titre à votre problème"  
                        size="medium"
                        name="name"
                        onChange={(e) => handleFormMailChange('name', e.target.value)} required
                        error={!!errorMessages.comment}
                        helperText={errorMessages.comment}/>
                    <TextField fullWidth label="Détaillez le" 
                            size="medium"
                            name="content"
                            onChange={(e) => handleFormMailChange('content', e.target.value)} 
                            multiline
                            rows={4}
                            required
                            error={!!errorMessages.comment}
                            helperText={errorMessages.comment}
                        />
                    <Button component="label"
                            role="contained"
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                            >
                            Importez une/des captures d'écran
                            <VisuallyHiddenInput 
                                type="file"
                                name='file'
                                multiple
                                onChange={(e) => handleFormMailChange('file', e.target.files)} />
                      </Button>
                      <div className={style.mainButtonContainer}>
                        <Button onClick={(e) => (sendMail())}
                                component="label"
                                role="contained"
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<SendIcon />}>
                            Envoyer ma demande
                        </Button>
                      </div>
                </>
              :
                ""
            }
          </form>
          {
            (displayNotif?.open === true) ? <Notification {...displayNotif}/>: ""
          }
      </div>
    </div>
  );
}

export default Profile;
