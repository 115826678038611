import * as React from 'react';
import Divider from '@mui/material/Divider';
import { User } from '../../../services/object/User';
import Avatar from '@mui/material/Avatar';
import style from './Utilisateur.module.scss';
import { UserData } from './Utilisateur';
import { App } from '../../../services/object/App';
import { StepIconProps } from '@mui/material/StepIcon';
import ExtensionIcon from '@mui/icons-material/Extension';
import ReactDOM from 'react-dom';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface SummaryProps {    
    formUsers: User | undefined;
    userData : UserData;
  }

function stringToColor(string: string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }
  
function stringAvatar(name: string) {
    if (name) {
        return {
        sx: {
            bgcolor: stringToColor(name),
            width: 150,
            height: 150,
            fontSize: '100px',
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1] ? name.split(' ')[1][0] : ''}`.toUpperCase(),
        };
    } else {
        return {
        sx: {
            bgcolor: '#000000',
            width: 150,
            height: 150,
            fontSize: '90px',
        },
        children: 'X',
        };
    }
}

export default function Summary({formUsers, userData} : SummaryProps) {
    const avatarProps = formUsers && formUsers.photo
        ? { src: formUsers.photo }
        : stringAvatar(`${formUsers?.firstname ?? ''} ${formUsers?.lastname ?? ''}`
    );

    const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))({
      [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 150,
      },
    });

  return (
    <div className={style.contentSummary}>
        <div>
            <Avatar sx={{ width: 150, height: 150}}{...avatarProps} />
        </div>
        <div className={style.dataSummary}>
            <h3 className={style.typoSummary}>{userData.user.firstname} {userData.user.lastname}</h3>
            <h3 className={style.typoSummary}>{formUsers?.email}</h3>
            <Divider className={style.dividSummary}/>
            <p>{userData.job.name}</p>

            <div className={style.univSaleSummary}>
                {userData.univers.map((univers, index) => (
                <div className={style.univSaleData} key={index}> 
                    <p>{univers.name}</p> 
                    <CustomWidthTooltip placement="right-end" arrow title={
                        <div>
                            {userData.salepoint
                                .filter(salepoint => salepoint.universUid === univers.uid)
                                .map((salepoint, index, array) => (
                                    <React.Fragment key={index}>
                                        {salepoint.name}
                                        {index === array.length - 1 ? "." : ", "}
                                    </React.Fragment>
                                ))} 
                        </div>
                    }>
                        <HelpOutlineOutlinedIcon htmlColor='#6e6e6e' sx={{width: '22px', height: '22px'}}/>
                    </CustomWidthTooltip>
                </div>))}   
            </div>

            <div className={style.appContainer}>
                {userData.app && userData.app
                            .sort((appA :App, appB :App) => {
                              if ( (appA.isJraApp ?? false) < (appB.isJraApp ?? false) ){
                                return 1;
                              }
                              if ( (appA.isJraApp ?? false) > (appB.isJraApp ?? false) ){
                                return -1;
                              }
                              return 0;
                            })
                            .map((app: App, index: number) => (
                <div className={style.appAndRoleSummary} key={index}>    
                    <div className={style.appSummary}>
                            {app.iconUrl ? (
                              <div className={style.iconUrl}>
                                <img src={app.iconUrl} alt={`${app.name} favicon`}/>
                              </div>
                            ) : (
                              <ExtensionIcon />
                            )}
                        {app.name}  
                    </div>
                    
                    {(app.isJraApp) && userData.role.filter(role => role.appUid === app.uid).length > 0 && (
                        <Tooltip placement="right-end" arrow title={
                        <div>
                            {userData.role
                            .filter(role => role.appUid === app.uid)
                            .map((role, index, array) => (
                                <React.Fragment key={index}>
                                  {role.name}
                                  {index === array.length - 1 ? "." : ", "}
                                </React.Fragment> 
                            ))}
                            </div>
                        }>
                            <HelpOutlineOutlinedIcon htmlColor='#6e6e6e' sx={{width: '22px', height: '22px'}}/>
                        </Tooltip>
                    )}
                </div>
                ))}
            </div>
        </div>   
    </div>
  );
};