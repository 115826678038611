import React, { useState, useEffect } from "react";

import style from '../law.module.scss';
import { App } from '../../../../services/object/App';
import { Button, IconButton, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

interface FormSupportProps {
  errorMessages: Record<string, string>,
  app: App | undefined,
  handleFormAppChange: (property: string, value: string) => void
}

const FormSupport = ({
  errorMessages,
  app,
  handleFormAppChange,
}: FormSupportProps) => {

  const [mailSupport, setMailSupport] = useState<string[]>([]);
  const [mailNumber, setMailNumber] = useState<number>(0);

  useEffect(() => {
    if (app) {
      setMailSupport(app.TMailSupport?.split('|') ?? []);
      setMailNumber(app.TMailSupport?.split('|').length ?? 0)
    }
  }, [app]);

  useEffect(() => {
    console.log(mailSupport)
  }, [mailSupport]);
  

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let index = parseInt(name.split('_')[1])
    let newMailSupport = mailSupport;
    newMailSupport[index] = value;
    setMailSupport(newMailSupport)
    handleFormAppChange('TMailSupport',newMailSupport.join('|'))
  };

  const addMail = () => {
    setMailNumber(mailNumber+1)
  }

  const onDelete = (index:number) => {
    let newMailSupport = mailSupport;
    newMailSupport.splice(index, 1);
    console.log(newMailSupport)
    setMailSupport(newMailSupport)
    handleFormAppChange('TMailSupport',newMailSupport.join('|'))
    setMailNumber(mailNumber-1)
  }

  let allMail = [];
  for (let index = 0; index < mailNumber; index++) {
    allMail.push(<TextField fullWidth label="Adresse mail"
                            value={mailSupport[index]}
                            size="medium"
                            name={"mailSupport_"+index}
                            onBlur={handleBlur}
                            required
                            error={!!errorMessages.name}
                            helperText={errorMessages.name} 
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                <IconButton onClick={(e) => (onDelete(index))}>
                                  <DeleteForeverIcon/>
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            />)
  }

  return (
    <div className={style.contentText}>
      {allMail.map((elem) => (elem))}
      <div className={style.fullWidth}>
        <Button className='addbutton'
          variant="contained"
          onClick={addMail}
          endIcon={<AddIcon />}>
          Ajouter un mail de support
        </Button>
      </div>
    </div>
  );
};

export default FormSupport;
