import React, { useState } from "react";
import style from "./assets/style/profile.module.scss";

import { Service_Api } from "../../services/Api";
import { useAuth } from "../../services/auth/useAuth";
import { User } from "../../services/object/User";

import Alert, { AlertProps } from "../../components/alert/Alert";
import Notification, {
  NotificationProps,
} from "../../components/notification/Notification";

import Cookies from "universal-cookie";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Pen from "@mui/icons-material/CreateOutlined";
import Button from "@mui/material/Button";

import CloseIcon from "@mui/icons-material/Close";

function ChangeProfil() {
  const { user, updateUser } = useAuth();
  const Api = Service_Api();
  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    user?.photo
  );
  const [openPopup, setOpenPopup] = useState(false);
  const [displayAlert, setDisplayAlert] = useState<AlertProps>();
  const [displayNotif, setDisplayNotif] = useState<NotificationProps>();
  const [formData, setFormData] = useState<User>({
    firstname: user?.firstname ?? "",
    jobUid: user?.jobUid ?? null,
    lastname: user?.lastname ?? "",
    position: user?.position ?? "",
    email: user?.email ?? "",
    photo: selectedImage || "",
    uid: user?.uid ?? "",
  });

  const getBase64 = (file: File, callback: (result: string) => void) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (typeof reader.result === "string") callback(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      getBase64(file, (result: string) => {
        onUserConnectChange("photo", result);
      });
    } else {
      setSelectedImage(undefined);
    }
  };
  const onCircleClick = () => {
    setOpenPopup(true);
  };
  const onSaveClick = async () => {
    setDisplayAlert({
      open: true,
      title: "Confirmation",
      contentText: "Êtes-vous sûr de vouloir modifier votre profil?",
      contentButtonSuccess: "Oui",
      contentButtonAbord: "Non",
      onSubmitSuccess: async () => {
        try {
          const response = await Api.put("user/", formData);
          const updatedUser = response?.data.user;
          if (updatedUser) {
            updateUser(updatedUser);
            const cookies = new Cookies();
            cookies.set("JRA_user", JSON.stringify(updatedUser), { path: '/' });
          } else {
            console.error("Aucune donnée utilisateur reçue en réponse");
          }
          setDisplayNotif({
            open: true,
            contentText: "Le profil a été mis à jour avec succès!",
            severity: "success",
            handleClose: () => setDisplayNotif(undefined),
          });
        } catch (error: any) {
          console.error("Erreur lors de la mise à jour du profil :", error.message);
          setDisplayNotif({
            open: true,
            contentText: "Aïe, une erreur est survenue lors de la mise à jour du profil.",
            severity: "error",
            handleClose: () => setDisplayNotif(undefined),
          });
        } finally {
          setDisplayAlert(undefined);
        }
      },
      onSubmitAbord: () => setDisplayAlert(undefined),
    });
  };
  const onUserConnectChange = (
    property: string,
    value: string | number
  ) => {
    setFormData((prevFormUsers: any) => ({
      ...prevFormUsers,
      [property]: value.toString(),
    }));
  };
  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleNewImage = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click(); // Ouvre le champ input file
    }
    setOpenPopup(false); // Fermer la popup après sélection de l'image
  };
  const handleDeleteImage = () => {
    setSelectedImage(undefined);
    onUserConnectChange("photo", "");
    setFormData((prevFormData) => ({
      ...prevFormData,
      photo: "",
    }));
    setOpenPopup(false);
  };

  return (
    <Box id={style.editProfileInfos} className={style.box}>
      <h2>Modifier mes infos</h2>
      <div className={style.content}>
        <div className={style.profilePhoto}>
          <div
            className={`${style.circleContainer} ${selectedImage ? style.active : ""}`}
            onClick={onCircleClick}
            style={{
              backgroundImage: selectedImage
                ? `url(${selectedImage})`
                : formData?.photo
                  ? `url(${formData?.photo})`
                  : "none",
            }}
          >
            <Pen />
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={onImageChange}
            id="fileInput"
            name="photo"
            style={{
              display: "none",
            }}
          />
        </div>
        <div className={style.profileInfos}>
          <TextField
            label="Prénom"
            value={formData.firstname}
            onChange={(e) =>
              onUserConnectChange("firstname", e.target.value)
            }
            fullWidth
          />
          <TextField
            label="Nom"
            value={formData.lastname}
            onChange={(e) =>
              onUserConnectChange("lastname", e.target.value)
            }
            fullWidth
          />
          <TextField
            label="Email"
            value={formData.email}
            onChange={(e) => onUserConnectChange("email", e.target.value)}
            fullWidth
          />
        </div>
      </div>
      <div className={style.buttonProfile}>
        <Button variant="contained" onClick={onSaveClick}>
          Enregistrer
        </Button>
      </div>

      <Dialog open={openPopup} onClose={handleClosePopup}>
        <DialogTitle>
          Modifier l'image
          <IconButton
            aria-label="close"
            onClick={handleClosePopup}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <p>Que souhaitez-vous faire avec l'image ?</p>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleNewImage}>Nouvelle image</Button>
          <Button onClick={handleDeleteImage} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>

      {displayAlert?.open === true ? <Alert {...displayAlert} /> : ""}
      {displayNotif?.open === true ? <Notification {...displayNotif} /> : ""}
    </Box>
  );
}

export default ChangeProfil;
