import style from './noMatch.module.scss';
import { useEffect } from "react";

function NoMatch() {

  useEffect(() => {
    document.title = "MyJra - 404";
  }, []);

    return (
        <>
          <div className={style.background}>
            <div className="backsquare">
                <h1>Quelque chose ne s'est pas passé comme prévu...</h1>
                La page que vous souhaitez afficher n'est pas disponible. Il y a plusieurs raisons qui pourraient expliquer cette erreur:
                <ul>
                    <li>
                      La page n'existe pas/plus.
                    </li>
                    <li>
                      Vous n'avez pas les autorisations nécessaire pour afficher cette page.
                    </li>
                </ul>
                <br/>
                Si le problème persiste, merci de contacter <a href="mailto:">dev@autobonplan.com</a>
            </div>
          </div>
        </>
      );
}


export default NoMatch;