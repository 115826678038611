import { useState } from "react";
import { App } from "../object/App";

export const useApp = () => {
  const getApp = (): App[] => {
    const appString = localStorage.getItem("JRA_app");
    if (!appString) return [{ uid: "", name: "", url: "", photo: "", iconUrl: "", isJraApp: false }];
    
    try {
      return JSON.parse(appString);
    } catch (error) {
      console.error("Failed to parse app data:", error);
      return [{ uid: "", name: "", url: "", photo: "", iconUrl: "", isJraApp: false }];
    }
  };

  const [TOApp, setTOApp] = useState<App[]>(getApp());

  const saveApp = (app: App[]) => {
    localStorage.setItem("JRA_app", JSON.stringify(app));
    setTOApp(app);
  };

  const removeTOApp = () => {
    localStorage.removeItem("JRA_app");
    setTOApp([{ uid: "", name: "", url: "", photo: "", iconUrl: "", isJraApp: false }]);
  };

  return { TOApp, setTOApp: saveApp, removeTOApp };
};
