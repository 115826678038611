import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import style from './input.module.scss';
import { useState, useEffect } from 'react';

interface CheckboxProps {
    legend: string;
    setCheckedObject: React.Dispatch<React.SetStateAction<string[]>>;
    Objet: any;
    checkedObject: string[];
    errorMessages: Record<string, string>;
    required: boolean;
    isSoloCheckbox?: boolean;
  }

export default function IndeterminateCheckbox({ Objet, legend, setCheckedObject, checkedObject, errorMessages, required, isSoloCheckbox }: CheckboxProps) {
    const [someChecked, setSomeChecked] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
  
    useEffect(() => {
      setSomeChecked(checkedObject.length > 0 && checkedObject.length < Objet.length);
      setAllChecked(checkedObject.length === Objet.length);
    }, [checkedObject, Objet.length]);

  const handleRolechange = (e: React.SyntheticEvent<Element, Event> ,objectUid : string) => {
    let target = e.target as HTMLInputElement;
    let updatedcheckedObject: string[]
    if (target.checked) {
      updatedcheckedObject = [...checkedObject, objectUid]
    } else {
      updatedcheckedObject = checkedObject.filter(uid => uid !== objectUid);
    };
    setSomeChecked(updatedcheckedObject.length > 0)
    setCheckedObject(updatedcheckedObject);
  } 
  

  const handleParentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckedObject(Objet.map((object: any) => object.uid));
    } else {
      setCheckedObject([]);
    }
  };

  let errorName = "";

  const children = (
    <>
            <FormGroup sx={{flexDirection: 'column', marginLeft: '15px'}}>
                {Objet.map((object: any) => {
                    errorName = ((checkedObject.length > 0) ? "" : object.name)
                return (<FormControlLabel
                    control={<Checkbox name={object.name} value={object.uid} checked={checkedObject.includes(object.uid)}/>}
                    key={object.uid}
                    label={object.name} 
                    name={object.name}
                    onChange={(e) => handleRolechange(e, object.uid)}
                    required={(someChecked || checkedObject.length > 0 || !required) ? false : required}
                />)
                })}
            </FormGroup>
    </>
  );

  return (
    <div className={style.containercheck}>
      <div className={`${style.uicheckbox} ${isSoloCheckbox ? style.soloCheckbox : ''}`}>
      <FormLabel required={(someChecked || checkedObject.length > 0 || !required) ? false : required} component="legend" error={(checkedObject.length === 0 && Object.keys(errorMessages).length !== 0)}>{legend}</FormLabel>
      <FormControlLabel
        label="Tout sélectionner"
        control={
          <Checkbox
            checked={allChecked}
            indeterminate={someChecked && !allChecked}
            onChange={handleParentChange}
          />
          }
      />
      {children}
      </div>
        <div className={style.errorMessage}> {errorMessages[errorName]}</div>
    </div>
  );
}
