import style from './salepoint.module.scss';
import styleModal from '../../../components/modal/modal.module.scss';
import { TextField } from '@mui/material';
import { Salepoint } from '../../../services/object/Salepoint';
import { ReactComponent as Pen} from '../../assets/editPhoto.svg';
import RadioOnly from '../../../components/input/RadioOnly';
import OpenningHoursComp from './openningHours/OpenningHoursComp';
import { Univers } from '../../../services/object/Univers';
import { OpenningHours } from '../../../services/object/OpenningHours';
import { OpenningHoursException } from '../../../services/object/OpenningHoursException';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize'
import React from 'react';

interface SalepointPopupChildrensProps {
    errorMessages: Record<string, string>,
    formRefSalepoint: React.RefObject<HTMLFormElement>,
    formRefHours: React.RefObject<HTMLFormElement>,
    salepoint: Salepoint|undefined;
    selectedImage: string | undefined
    universData : Univers[]
    openningHours: OpenningHours[] | undefined
    openningHoursException: OpenningHoursException[] | undefined
    handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleFormSalepointChange: (property: string, value: string) => void
    handleCircleClick: () => void
    handleFormHoursExceptionChange: (uid: string, property: keyof OpenningHoursException, value: never) => void
    handleFormHoursChange: (uid: string, property: keyof OpenningHours, value: never) => void
    setOpenningHours: React.Dispatch<React.SetStateAction<OpenningHours[] | undefined>>
    setOpenningHoursException: React.Dispatch<React.SetStateAction<OpenningHoursException[] | undefined>>

  }
    const salepointPopupChildrens = ({
                                      errorMessages,
                                      formRefSalepoint,
                                      formRefHours,
                                      salepoint,
                                      selectedImage, 
                                      universData,
                                      openningHours,
                                      openningHoursException,
                                      handleFormSalepointChange, 
                                      handleCircleClick, 
                                      handleImageChange,
                                      handleFormHoursExceptionChange,
                                      handleFormHoursChange,
                                      setOpenningHours,
                                      setOpenningHoursException} :SalepointPopupChildrensProps) => {

    
    //event change on radio input
    const onChangeRadio = (universUid: string) => {
        //trigger handleFormChange
        handleFormSalepointChange("universUid", universUid)
    };
    return ([
                <form className={style.uiform} ref={formRefSalepoint}>
                    <h2  className={styleModal.subtitle}>Les informations générales :</h2>
                    <div className={style.contentText_img}>
                        <TextField fullWidth label="Nom"  
                            size="small"
                            value={salepoint?.name}
                            name="name"
                            onChange={(e) => handleFormSalepointChange('name', e.target.value)} required
                            error={!!errorMessages.name}
                            helperText={errorMessages.name}/>
                        <TextField fullWidth label="Nom (externe)"  
                            size="small"
                            value={salepoint?.gmbName}
                            name="gmbName"
                            onChange={(e) => handleFormSalepointChange('gmbName', e.target.value)} required
                            error={!!errorMessages.gmbName}
                            helperText={errorMessages.gmbName}
                            title='Diffusable sur Google par exemple'/>
                        <TextField fullWidth label="Adresse"  
                            size="small"
                            value={salepoint?.address}
                            name="address"
                            onChange={(e) => handleFormSalepointChange('address', e.target.value)} required
                            error={!!errorMessages.address}
                            helperText={errorMessages.address}/>
                        <TextField fullWidth label="Ville"  
                            size="small"
                            value={salepoint?.city}
                            name="city"
                            onChange={(e) => handleFormSalepointChange('city', e.target.value)} required
                            error={!!errorMessages.city}
                            helperText={errorMessages.city}/>
                        <TextField fullWidth label="Code Postal" 
                            size="small"
                            value={salepoint?.zip}
                            name="zip"
                            onChange={(e) => handleFormSalepointChange('zip', e.target.value)}
                            type='number' required
                            error={!!errorMessages.zip}
                            helperText={errorMessages.zip}/>
                        <TextField fullWidth label="Téléphone" 
                            size="small"
                            value={salepoint?.phone}
                            name="phone"
                            onChange={(e) => handleFormSalepointChange('phone', e.target.value)}
                            type='number' required
                            error={!!errorMessages.phone}
                            helperText={errorMessages.phone}/>
                        <TextField fullWidth label="Téléphone secondaire" 
                            size="small"
                            value={salepoint?.secondPhone}
                            name="zip"
                            onChange={(e) => handleFormSalepointChange('secondPhone', e.target.value)}
                            type='number'
                            error={!!errorMessages.secondPhone}
                            helperText={errorMessages.secondPhone}/>
                        <TextField fullWidth label="Raison sociale" 
                            size="small"
                            value={salepoint?.companyName}
                            name="companyName"
                            onChange={(e) => handleFormSalepointChange('companyName', e.target.value)}required
                            error={!!errorMessages.companyName}
                            helperText={errorMessages.companyName} />
                        <TextField fullWidth label="SIRET" 
                            size="small"
                            value={salepoint?.siret}
                            name="siret"
                            onChange={(e) => handleFormSalepointChange('siret', e.target.value)} required
                            error={!!errorMessages.siret}
                            helperText={errorMessages.siret} />
                        <TextField fullWidth label="Identifiant GMB" 
                            size="small"
                            value={salepoint?.gmbId}
                            name="gmbId"
                            onChange={(e) => handleFormSalepointChange('gmbId', e.target.value)}
                            type='number' required
                            error={!!errorMessages.gmbId}
                            helperText={errorMessages.gmbId} />
                        <TextField fullWidth label="Url de la concession" 
                            size="small"
                            value={salepoint?.website}
                            name="website"
                            onChange={(e) => handleFormSalepointChange('website', e.target.value)}
                            type='text' required
                            error={!!errorMessages.website}
                            helperText={errorMessages.website} />
                        <div className={style.imgContainer + ((selectedImage) ? " active" : "")}
                            onClick={handleCircleClick}
                            style={{
                                    backgroundImage: selectedImage ? `url(${selectedImage})` : salepoint?.photo ? `url(${salepoint?.photo})` : 'none',
                                }}>
                            <Pen/>
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            id="fileInput"
                            style={{
                                display: 'none',
                            }}
                        />
                    </div>
                    <div className={style.checkContenair}>
                        <div className={style.textAreaContenair}>
                            <BaseTextareaAutosize name="description" placeholder="Description" value={salepoint?.description} onChange={(e) => handleFormSalepointChange('description', e.target.value)}></BaseTextareaAutosize>
                        </div>
                        <RadioOnly Objet={universData} 
                                legend="Rattacher un univers"
                                onChange={onChangeRadio}
                                checkedUid={salepoint?.universUid}
                                errorMessages={errorMessages}
                                required={true}/>
                    </div>
                </form>
                ,
                <form className={style.uiform} ref={formRefHours}>
                    <h2 className={styleModal.subtitle}>Les horaires d'ouverture standard:</h2>
                    <OpenningHoursComp 
                        handleFormHoursExceptionChange={handleFormHoursExceptionChange} 
                        handleFormHoursChange={handleFormHoursChange} 
                        openningHours={openningHours} 
                        openningHoursException={openningHoursException}
                        setOpenningHours={setOpenningHours}
                        setOpenningHoursException={setOpenningHoursException}/>
                </form>
           ]
    )
}
export default salepointPopupChildrens;