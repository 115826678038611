import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

interface ControllableStatesProps {
  legend: string;
  onChange: (object: string) => void;
  Objet: { uid: string; name: string }[];
  selecteUid: string | undefined;
  errorMessages: Record<string, string>;
}

export default function ControllableStates({ Objet, legend, onChange, selecteUid, errorMessages }: ControllableStatesProps) {
  const [selectedValue, setSelectedValue] = React.useState<string | null>(null);

  React.useEffect(() => {
    const selectedObject = Objet.find((object) => object.uid === selecteUid);
    setSelectedValue(selectedObject ? selectedObject.name : null);
  }, [selecteUid, Objet]);

  const handleOnChange = (event: any, value: { uid: string; name: string } | null) => {
    setSelectedValue(value ? value.name : null);
    onChange(value ? value.uid : '');
  };

  const selectedObjectUid = Objet?.find((object) => object.name === selectedValue)?.uid;

  return (
    <Autocomplete
      options={Objet}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.uid === value.uid}
      fullWidth
      onChange={handleOnChange}
      value={Objet.find((object) => object.name === selectedValue) ?? null}
      renderInput={(params) => (
        <TextField
          {...params}
          label={legend}
          required
          error={!!errorMessages[selectedObjectUid ?? '']}
          helperText={errorMessages[selectedObjectUid ?? '']}
        />
      )}
    />
  );
}



