
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select  from '@mui/material/Select';
import style from './openningHours.module.scss';

import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { OpenningHoursException } from '../../../../services/object/OpenningHoursException';
import { Backdrop, IconButton, SpeedDial, SpeedDialAction } from '@mui/material';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { DatePicker, LocalizationProvider, frFR } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useAuth } from '../../../../services/auth/useAuth';
import { dateJstoSqlDate } from '../../../../services/tools/translateDate';

interface OpenningHoursExeptionProps {
    openningHoursException: OpenningHoursException;
    firstOfDay:boolean;
    menuItemList: () => JSX.Element[];
    handleFormHoursExceptionChange:(uid: string, property: keyof OpenningHoursException, value: never) => void;
    addOpenningHoursException: (newopenningHours: OpenningHoursException) => Promise<void>;
    removeOpenningHoursException: (newOpenningHoursException: OpenningHoursException) => Promise<void>;
  }

function OpenningHoursExeption({
    openningHoursException, 
    firstOfDay, 
    menuItemList, 
    handleFormHoursExceptionChange,
    addOpenningHoursException,
    removeOpenningHoursException,
}: OpenningHoursExeptionProps) {

    const [isDeleted, setIsDeleted] = useState(false);
    const [isClose, setIsClose] = useState<boolean>(openningHoursException?.isClose);
    const { user } = useAuth();

    useEffect(() => {
        setIsClose(openningHoursException?.isClose);
    }, 
    [openningHoursException])
      
    const onClickHandlerAdd = async () => {
        let data = { 
            date : openningHoursException.date,
            hourStart : "08:00",
            hourEnd :  "18:00",
            userUid : user.uid,
            salepointUid : openningHoursException.salepointUid,
            isAdditionnal : true,
            isClose : false
        } as OpenningHoursException

        addOpenningHoursException(data);
        setOpen(false)
    };
    

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const onClickHandlerEdit = () => {
        setIsClose(false)
        handleFormHoursExceptionChange(openningHoursException?.uid, "isClose", 0 as never)
    };
    const onClickHandlerClose = () => {
        setIsClose(true)
        handleFormHoursExceptionChange(openningHoursException?.uid, "isClose", 1 as never)
    };
    
    const actionsOpen = [
        { icon: <DeleteIcon onClick={(e) => (removeOpenningHoursException(openningHoursException))}/>, name: 'Supprimer' },
        { icon: <AddIcon  onClick={onClickHandlerAdd}/>, name: 'Ajouter' },
        { icon: <DoDisturbIcon onClick={onClickHandlerClose}/>, name: 'Fermé pour la journée' },
      ];

    const actionsClose = [
        { icon: <DeleteIcon onClick={(e) => (removeOpenningHoursException(openningHoursException))}/>, name: 'Supprimer' },
        { icon: <EditIcon onClick={onClickHandlerEdit}/>, name: 'Ajouter des horaires' },
    ];
    return (
        <>
        { (!isDeleted) ? 
            <li>
                <div className={style.itemContainer}>
                
                    { ((firstOfDay) ? 
                    <LocalizationProvider 
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="fr"
                                    localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <DatePicker className={style.day} 
                                    value={dayjs(openningHoursException?.date.split(" ")[0])} 
                                    defaultValue={dayjs(openningHoursException?.date.split(" ")[0])} 
                                    label="Date"
                                    onChange={value => handleFormHoursExceptionChange(openningHoursException?.uid, "date", (((value?.toISOString())) ? dateJstoSqlDate(new Date(value.toISOString())) : "") as never)} />
                    
                    </LocalizationProvider>
                    : 
                        <span className={style.day}></span>
                    ) }
                    
                    { ((isClose && openningHoursException.isClose) ? 
                        <span className={style.hours}>
                            Fermé toute la journée
                        </span>
                    : 
                        <>
                            <FormControl className={style.inputContainer} sx={{ m: 1, minWidth: 150}}>
                                <InputLabel id="demo-simple-select-standard-label">Ouverture</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={openningHoursException?.hourStart}
                                    onChange={e => handleFormHoursExceptionChange(openningHoursException?.uid, "hourStart", e.target.value as never)}
                                    label="Ouverture">
                                    {
                                    menuItemList().map(
                                        (element) => {
                                            return element;
                                    })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl className={style.inputContainer} sx={{ m: 1, minWidth: 150}}>
                                <InputLabel id="demo-simple-select-standard-label">Fermeture</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={openningHoursException?.hourEnd}
                                    onChange={e =>  handleFormHoursExceptionChange(openningHoursException?.uid, "hourEnd", e.target.value as never)}
                                    label="Fermeture">
                                    {
                                        menuItemList().map(
                                            (element) => {
                                                return element;
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </>
                    ) }
                    {
                    (firstOfDay === true) ? 
                        <div className={style.dialContent}>
                            <SpeedDial
                                className={style.dial} 
                                ariaLabel="SpeedDial element"
                                sx={{ position: 'absolute'}}
                                icon={<SpeedDialIcon />}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                open={open}
                                direction={"left"}>
                                {
                                    (isClose && openningHoursException.isClose) ? 
                                        actionsClose.map((action) => (
                                            <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                                onClick={handleClose}
                                            />
                                        ))
                                    :
                                        actionsOpen.map((action) => (
                                            <SpeedDialAction
                                                key={action.name}
                                                icon={action.icon}
                                                tooltipTitle={action.name}
                                                onClick={handleClose}
                                            />
                                        ))
                                }
                            </SpeedDial>
                        </div>
                    : 
                    (openningHoursException?.isAdditionnal) ? 
                        <IconButton className={style.icon} onClick={(e) => removeOpenningHoursException(openningHoursException)}><DeleteIcon/></IconButton>
                    : 
                        <span className={style.fkDayButton}></span>
                    }
                    
                </div>
            </li>
        : ""
        }
    </>
      );
}


export default OpenningHoursExeption;