import style from './crossOrgin.module.scss';
import logo from '../../assets/img/Logo_JRA_H_NOIR.png';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../services/auth/useAuth';

function WaitingPage() {
    const {state} = useLocation();
    const { userPost,
            lawPost,
            appPost,
            jobPost } = state;
            
    const { login } = useAuth();

    const [token, setToken] = useState<string>(localStorage.getItem("JRA_token") ?? "");
    const [user, setUser] = useState<string>(localStorage.getItem("JRA_user") ?? "");
    const [law, setLaw] = useState<string>(localStorage.getItem("JRA_law") ?? "");
    const [app, setApp] = useState<string>(localStorage.getItem("JRA_app") ?? "");
    const [job, setJob] = useState<string>(localStorage.getItem("JRA_job") ?? "");
    
    useEffect(()=>{
        document.title = "MyJra - Connexion";

        if((token && token !== "")
        && (user && user !== "")
        && (app && app !== "")
        && (job && job !== "")){
            setTimeout(()=>{
                if(((new Cookies()).get('crossConnexion')) && new Date(((new Cookies()).get('crossConnexion')).expires) > new Date()){
                    window.location.href = atob((new Cookies()).get('crossConnexion').app);
                }else{
                    window.location.href = "/accueil";
                }
            }, 2000)
        }else if(userPost.JWT !== ""
                && userPost !== ""
                && appPost !== ""
                && jobPost !== ""){
            login(userPost, lawPost, appPost, jobPost, userPost.JWT);
            setToken(userPost)
            setUser(userPost.JWT)
            setLaw(lawPost)
            setJob(jobPost)
            setApp(appPost)
        }

    },[token, user, law, app, job, jobPost, userPost, lawPost, appPost])

    return (
        <>
            <div className={style.content}>
                <img src={logo} alt="logo"/><br/>
                <div>
                    Connexion en cours... <br/>
                    Vous serez bientôt redirigé.
                </div>
            </div>
        </>
    );
  };
  
  export default WaitingPage;