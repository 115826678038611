
export async function fetchFavicon(url: string): Promise<string> {
    try {
        const response = await fetch(url);
        const html = await response.text();
        const iconMatch = html.match(
            /<link\s+[^>]*rel=["'](?:shortcut icon|icon)["'][^>]*href=["']([^"']+)["']/i
        );

        if (iconMatch && iconMatch[1]) {
            const iconUrl = new URL(iconMatch[1], url).href;

            try {
                const iconResponse = await fetch(iconUrl, { method: "HEAD" });
                if (iconUrl.includes("callcenter")) {
                    console.log('iconResponse:', iconResponse);
                }

                if (iconResponse.ok) {
                    return iconUrl;
                } else {
                    return "";  // URL inaccessible
                }
            } catch (iconError) {
                console.error("Error fetching icon:", iconError);
                return "";  // Erreur d'accès à l'icône
            }
        } else {
            return "";  // Pas d'icône trouvée
        }
    } catch (error) {
        console.error("Error fetching favicon:", error);
        return "";
    }
}