import { Autocomplete, Checkbox, FormControl, FormHelperText, TextField, Tooltip } from "@mui/material";
import { Job } from "../../../../services/object/Job";
import { Univers } from "../../../../services/object/Univers";
import style from '../law.module.scss';
import { Salepoint } from "../../../../services/object/Salepoint";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { SyntheticEvent, useEffect, useState } from "react";
import { ReactComponent as Close } from "../../../../pages/assets/closeWindows.svg";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;          

interface MultiSelectProps {
    arrayJobLabel : [string[]] | undefined, 
    ToJob: Job[],
    arrayUniversLabel : [string[]] | undefined, 
    TOUnivers: Univers[],
    arraySalepointLabel : [string[]] | undefined, 
    TOSalepoint: Salepoint[],
    errorMessages: Record<string, string>,
    setArraySalepointLabel: React.Dispatch<React.SetStateAction<[string[]]| undefined>>,
    setArrayUniversLabel: React.Dispatch<React.SetStateAction<[string[]]| undefined>>,
    setArrayJobLabel: React.Dispatch<React.SetStateAction<[string[]]| undefined>>,
    exceptionNumber:number
    canBeDeleted?: boolean,
    onDelete?: () => void
}

const MultiSelect = ({arrayJobLabel,
                      ToJob,
                      arrayUniversLabel,
                      TOUnivers,
                      arraySalepointLabel,
                      TOSalepoint,
                      errorMessages,
                      setArraySalepointLabel,
                      setArrayUniversLabel,
                      setArrayJobLabel,
                      exceptionNumber,
                      canBeDeleted,
                      onDelete}:MultiSelectProps)=>{
    

    const [localFormValuesJob, setLocalFormValuesJob] = useState(arrayJobLabel && arrayJobLabel[exceptionNumber])
    const [localFormValuesUnivers, setLocalFormValuesUnivers] = useState(arrayUniversLabel && arrayUniversLabel[exceptionNumber])
    const [localFormValuesSalepoint, setLocalFormValuesSalepoint] = useState(arraySalepointLabel && arraySalepointLabel[exceptionNumber])

    useEffect(() => {
      if (localFormValuesJob) {
        let allValue = arrayJobLabel ?? [[]]
        allValue[exceptionNumber] = localFormValuesJob
        setArrayJobLabel(allValue);
        console.log("al")
      }
    }, [localFormValuesJob]);

    useEffect(() => {
      if (localFormValuesSalepoint) {
        let allValue = arraySalepointLabel ?? [[]]
        allValue[exceptionNumber] = localFormValuesSalepoint
        setArraySalepointLabel(allValue);
      }
    }, [localFormValuesSalepoint]);

    useEffect(() => {
      if (localFormValuesUnivers) {
        let allValue = arrayUniversLabel ?? [[]]
        allValue[exceptionNumber] = localFormValuesUnivers
        setArrayUniversLabel(allValue);
      }
    }, [localFormValuesUnivers]);

  
    const handleChangeJob= (event: SyntheticEvent<Element, Event>, value: Job[], index:number) => {
      let Tjob = ToJob.filter((job : Job) => { 
          return value.includes(job)
      })
      setLocalFormValuesJob(Tjob.map((job :Job) => {return job.name}))
    };
    
    const handleChangSalepoint= (event: SyntheticEvent<Element, Event>, value: Salepoint[]) => {
      setLocalFormValuesSalepoint(value.map((salepoint)=> (salepoint.name)));
    };  

    const handleChangUnivers= (event: SyntheticEvent<Element, Event>, value: Univers[]) => {
      setLocalFormValuesUnivers(value.map((univers)=> (univers.name)));
      setLocalFormValuesSalepoint(TOSalepoint.filter((salepoint : Salepoint) => (value.filter((univers :Univers) => (salepoint.universUid === univers.uid)).length > 0 )).map((salepoint :Salepoint) => {return salepoint.name}))
    };
    return <div className={style.selectContainer}>
            <h3>
              Attribution {exceptionNumber+1}
              {
              (canBeDeleted) ?
                <Close className={style.deleteElement} onClick={onDelete}/>
              : ""
              }
            </h3>
           
            <FormControl>
              <Autocomplete
                title={localFormValuesJob?.join(', ')}
                multiple
                className={style.autocomplete}
                limitTags={2}
                options={ToJob}
                disableCloseOnSelect
                getOptionLabel={(job :Job) => job.name}
                onChange={(e, value) => (handleChangeJob(e, value, exceptionNumber))}
                renderOption={(props, job, { selected = (localFormValuesJob?.indexOf(job.name) ?? -1 )> -1 }) => {
                    const { ...optionProps } = props;
                    return (
                    <li  {...optionProps}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            value={job.name}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={(localFormValuesJob?.indexOf(job.name) ?? -1) > -1 || selected}
                            />
                        {job.name}
                    </li>
                    );
                }}
                value={ToJob.filter((job:Job)=>{
                    if((localFormValuesJob?.indexOf(job.name) ?? -1) > -1) {
                        return job 
                      }
                })}
                renderInput={ params => {
                    const { InputProps, ...restParams } = params;
                    const { startAdornment, ...restInputProps } = InputProps;
                    return (
                      <TextField
                        required={(localFormValuesJob?.length === 0)}
                        name='job'
                        error={!!errorMessages.job}
                        { ...restParams }
                        label="Métiers(s) concerné(s)"
                        InputProps={ {
                          ...restInputProps,
                          startAdornment: (
                            <div className={style.scrollDiv}>
                              {startAdornment}
                            </div>
                          ),
                        } }
                      />
                    );
                  } }
                />
              <FormHelperText className='error'>{errorMessages.job}</FormHelperText>
          </FormControl>
          <FormControl >
                <Autocomplete
                    title={localFormValuesUnivers?.join(', ')}
                    multiple
                    className={style.autocomplete}
                    limitTags={2}
                    options={TOUnivers}
                    disableCloseOnSelect
                    getOptionLabel={(univers :Univers) => univers.name}
                    onChange={handleChangUnivers}
                    renderOption={(props, univers, { selected = (localFormValuesUnivers?.indexOf(univers.name) ?? -1 ) > -1 }) => {
                        const { ...optionProps } = props;
                        return (
                        <li  {...optionProps}>
                            <Checkbox
                                icon={icon}
                                value={univers.name}
                                name={univers.name}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={((localFormValuesUnivers?.indexOf(univers.name) ?? -1) > -1) || selected}
                                />
                            {univers.name}
                        </li>
                        );
                    }}
                    value={TOUnivers.filter((univers:Univers)=>{
                          if((localFormValuesUnivers?.indexOf(univers.name) ?? -1) > -1) {
                            return univers 
                          }
                    })}
                    renderInput={ params => {
                        const { InputProps, ...restParams } = params;
                        const { startAdornment, ...restInputProps } = InputProps;
                        return (
                          <TextField
                            required={(localFormValuesUnivers?.length === 0)}
                            name='univers'
                            error={!!errorMessages.univers}
                            { ...restParams }
                            label="Plaque(s)"
                            InputProps={ {
                              ...restInputProps,
                              startAdornment: (
                                <div className={style.scrollDiv}>
                                  {startAdornment}
                                </div>
                              ),
                            } }
                          />
                        );
                      } }
                    />
                <FormHelperText className='error'>{errorMessages.univers}</FormHelperText>
            </FormControl>
            <FormControl >
              <Autocomplete
                  title={localFormValuesSalepoint?.join(', ')}
                  multiple
                  className={style.autocomplete}
                  limitTags={2}
                  options={TOSalepoint}
                  disableCloseOnSelect
                  getOptionLabel={(salepoint :Salepoint) => salepoint.name}
                  onChange={handleChangSalepoint}
                  renderOption={(props, salepoint, { selected = ((localFormValuesSalepoint?.indexOf(salepoint.name) ?? -1) > -1) }) => {
                      const { ...optionProps } = props;
                      return (
                      <li  {...optionProps}>
                          <Checkbox
                              icon={icon}
                              value={salepoint.name}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={(localFormValuesSalepoint?.indexOf(salepoint.name)  ?? -1) > -1 || selected}
                              />
                          {salepoint.name}
                      </li>
                      );
                  }}
                  value={TOSalepoint.filter((salepoint:Salepoint)=>{
                      if((localFormValuesSalepoint?.indexOf(salepoint.name)  ?? -1) > -1) {
                          return salepoint 
                        }
                  })}
                  renderInput={ params => {
                      const { InputProps, ...restParams } = params;
                      const { startAdornment, ...restInputProps } = InputProps;
                      return (
                        <TextField
                          required={(localFormValuesSalepoint?.length === 0)}
                          name='salepoint'
                          error={!!errorMessages.salepoint}
                          { ...restParams }
                          label="Concession(s)"
                          InputProps={ {
                            ...restInputProps,
                            startAdornment: (
                              <div className={style.scrollDiv}>
                                {startAdornment}
                              </div>
                            ),
                          } }
                        />
                      );
                    } }
                  />
              <FormHelperText className='error'>{errorMessages.salepoint}</FormHelperText>
          </FormControl>
        </div>
    }  
export default MultiSelect;