import React from 'react';
import style from '../law.module.scss';

import { App } from '../../../../services/object/App';
import { Job } from '../../../../services/object/Job';
import { Univers } from '../../../../services/object/Univers';
import { Salepoint } from '../../../../services/object/Salepoint';

import FormApp from './FormApp'
import FormSupport from './FormSupport';
import FormVideo from './FormVideo';
import { Video } from '../../../../services/object/Video';
import { Role } from '../../../../services/object/Role';

interface AppPopupChildrensProps {
  errorMessages: Record<string, string>,
  formRefApp: React.RefObject<HTMLFormElement>,
  app: App | undefined,
  handleFormAppChange: (property: string, value: string) => void
  ToJob: Job[]
  arrayJobLabel: [string[]] | undefined
  arrayUniversLabel: [string[]] | undefined
  TOSalepoint: Salepoint[]
  arraySalepointLabel: [string[]] | undefined
  setArraySalepointLabel: React.Dispatch<React.SetStateAction<[string[]]| undefined>>
  setArrayUniversLabel: React.Dispatch<React.SetStateAction<[string[]]| undefined>>
  setArrayJobLabel: React.Dispatch<React.SetStateAction<[string[]]| undefined>>
  TOUnivers: Univers[]
  setExceptionNumber: React.Dispatch<React.SetStateAction<number>>
  exceptionNumber: number
  TOvideo: Video[]
  ToRole: Role[]
  setTOvideo: React.Dispatch<React.SetStateAction<Video[]>>
  handleOpenTinyModalApp: (ToRole: Role[], TOvideo: Video[]) => void
  arrayRoleLabel: string[],
  onVideoChange: (file: File, videoUid: string, title: string)=> Promise<void>
}

const AppPopupChildrens = ({
  errorMessages,
  formRefApp,
  app,
  TOvideo,
  setTOvideo,
  ToJob,
  arrayJobLabel,
  TOSalepoint,
  arraySalepointLabel,
  setArraySalepointLabel,
  setArrayJobLabel,
  TOUnivers,
  arrayUniversLabel,
  setArrayUniversLabel,
  handleFormAppChange,
  setExceptionNumber,
  exceptionNumber,
  ToRole,
  handleOpenTinyModalApp,
  arrayRoleLabel,
  onVideoChange
}: AppPopupChildrensProps) => {

  let formApp = [
                  <form className={style.uiform} ref={formRefApp}>
                      <FormApp
                        errorMessages={errorMessages}
                        app={app}
                        ToJob={ToJob}
                        arrayJobLabel={arrayJobLabel}
                        TOSalepoint={TOSalepoint}
                        arraySalepointLabel={arraySalepointLabel}
                        setArraySalepointLabel={setArraySalepointLabel}
                        setArrayJobLabel={setArrayJobLabel}
                        TOUnivers={TOUnivers}
                        arrayUniversLabel={arrayUniversLabel}
                        setArrayUniversLabel={setArrayUniversLabel}
                        handleFormAppChange={handleFormAppChange}
                        setExceptionNumber={setExceptionNumber}
                        exceptionNumber={exceptionNumber}
                      />
                  </form>,
                  <form className={style.uiform} ref={formRefApp}>
                      <FormSupport
                        errorMessages={errorMessages}
                        app={app}
                        handleFormAppChange={handleFormAppChange}
                      />
                  </form>,
                  <form className={style.uiform} ref={formRefApp}>
                    <FormVideo
                      errorMessages={errorMessages}
                      app={app}
                      onVideoChange={onVideoChange}
                      TOvideo={TOvideo}
                      ToRole={ToRole}
                      setTOvideo={setTOvideo}
                      handleOpenTinyModalApp={handleOpenTinyModalApp}
                      arrayRoleLabel={arrayRoleLabel}
                    />
                  </form>                
                ];
       
  return (formApp)
}
export default AppPopupChildrens;