import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Popin } from '../../../services/object/Popin';
import style from './Popin.module.scss'
import { Service_Api } from '../../../services/Api';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { usePopup } from '../../../services/auth/usePopup';
import Zoom from '@mui/material/Zoom';

function Popup () {
    const { isPopupDisplayed, markPopupAsDisplayed } = usePopup();
    const [userPopins, setUserPopins] = useState<Popin[]>([]);
    const [currentPopinIndex, setCurrentPopinIndex] = useState(0);
    const [fetchingData, setFetchingData] = useState(false);
    const Api = Service_Api();

    

    useEffect(() => {

        const fetchUserPopins = async (): Promise<Popin[]> => {
            try {
                const response = await Api.get(`popin/getPopinForToday`);
                const userPopins = response?.data || [];
                console.log('Popin de l\'utilisateur récupérée avec succès');
                return userPopins;
            } catch (error) {
                console.error('Erreur lors de la récupération de la popin de l\'utilisateur', error);
                return []; 
            }
        }

        const fetchData = async () => {
            try {
                const popins = await fetchUserPopins();
                const filteredPopins = popins.filter(popin => !isPopupDisplayed(popin.uid));
                setUserPopins(filteredPopins);
            } catch (error) {
                console.error('Erreur lors de la récupération des popins de l\'utilisateur', error);
            }
        };

        if (!fetchingData) {
            setFetchingData(true);
            fetchData();
        }
    }, [Api, isPopupDisplayed, fetchingData]);

    const handleClose = () => { 
        const currentPopin = userPopins[currentPopinIndex];
        markPopupAsDisplayed(currentPopin.uid);
        if (currentPopinIndex < userPopins.length - 1) {
            setCurrentPopinIndex(currentPopinIndex + 1)
        } else {
            setCurrentPopinIndex(0);
            setUserPopins([]);
        }
    }   

    return (
        <>
            {userPopins.length > 0 && (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                    <Modal open={true} onClose={handleClose}>
                        <Zoom in={true} timeout={500}>
                            <Box className={style.boxPopup}>
                                <Card sx={{ width: 600, height: 'auto', paddingBottom: '1rem', border: 'none' }}>
                                    <CardMedia height="350" component="img" alt="image" image={userPopins[currentPopinIndex]?.imageUrl} />
                                    <CardContent sx={{ paddingInline: '2rem', justifyContent: 'center', border: 'none' }}>
                                        <Typography gutterBottom variant="h5" component="div">{userPopins[currentPopinIndex]?.title}</Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ fontSize: 15 }}>{userPopins[currentPopinIndex]?.content}</Typography>
                                    </CardContent>
                                    <CardActions sx={{ bottom: '5px', justifyContent: 'center',  border: 'none' }}>
                                        <Button size="large" onClick={handleClose}>{userPopins[currentPopinIndex]?.buttonText}</Button>
                                    </CardActions>
                                </Card>
                            </Box>
                        </Zoom>
                    </Modal>
                </Backdrop>
            )}
        </>
    );
};

export default Popup;
