import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// style
import "../../../App.scss";
import style from "./styles/accueilConfig.module.scss";
import "../../../components/tables/Table.scss";

// General components
import TableChart from "../../../components/tables/Tablechart";
import ModalFormComponent from "../../../components/modal/ModalFormComponent";
import Notification, {
  NotificationProps,
  renderNotif,
} from "../../../components/notification/Notification";
import { Loader } from "../../../components/loader/Loader";
import Alert, { AlertProps } from "../../../components/alert/Alert";

// AccueilConfig components
import ModalPost from "./modals/ModalPost";
import ModalPostStats from "./modals/ModalPostStats";

// Services
import { Service_Api } from "../../../services/Api";
import { Post } from "../../../services/object/Post";
import { PostStats, postStatsUid } from "../../../services/object/PostStats";
import { SalepointData } from "../../../services/object/Salepoint";
import { jsDateToLocalFr } from "../../../services/tools/translateDate";

// MUI
import {
  GridRenderCellParams,
  GridCallbackDetails,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid/";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { green, red } from "@mui/material/colors";

import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import ListIcon from '@mui/icons-material/List';


export interface ChildProps {
  uid: string;
  parentUid: string;
  value: boolean;
}

function AccueilConfig() {
  const Api = Service_Api();

  const [loader, setLoader] = useState<boolean>(true);

  // table
  const [posts, setPosts] = useState<Post[]>([]);
  const [rows, setRows] = useState<{
    id: string;
    title: string;
    date: Date | null;
  }[]
  >([]);
  // modal posts
  const [isModalPostOpen, setIsModalPostOpen] = useState(false);
  const [isModalPostStatsOpen, setIsModalPostStatsOpen] = useState(false);
  const formRefPost = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
  const formRefPostStats = useRef<HTMLFormElement>() as React.RefObject<HTMLFormElement>;
  const [formPost, setFormPost] = useState<Post | undefined>();
  const [formPostStats, setFormPostStats] = useState<PostStats | undefined>();
  const [modalPostComponent, setModalPostComponent] = useState<JSX.Element[]>();
  const [modalPostStatsComponent, setModalPostStatsComponent] = useState<JSX.Element[]>();
  const [isNewPost, setIsNewPost] = useState(true);

  const [errorPostMessages, setErrorPostMessages] = useState<Record<string, string>>({});
  const [errorPostStatsMessages, setErrorPostStatsMessages] = useState<Record<string, string>>({});
  const [displayAlert, setDisplayAlert] = useState<AlertProps>();
  const [notif, setNotif] = useState<NotificationProps>();

  const columns = [
    {
      field: "title",
      headerName: "Titre",
      flex: 0.1,
      minWidth: 100,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 0.1,
      minWidth: 100,
      maxWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return jsDateToLocalFr(params.row.date);
      },
    },
    {
      field: "univers",
      headerName: "Plaque",
      flex: 0.19,
      minWidth: 100,
      maxWidth: 170,
    },
    {
      field: "salepoint",
      headerName: "Concession",
      flex: 0.19,
      minWidth: 150,
      maxWidth: 170,
    },
    {
      field: "newEmployeeJob",
      headerName: "Métier",
      flex: 0.19,
      minWidth: 100,
      maxWidth: 170,
    },
    {
      field: "photo",
      headerName: "Photo",
      flex: 0.19,
      minWidth: 100,
      maxWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <span
            className={style.photoPostCell}
            style={{ backgroundImage: 'url("' + params.value + '")' }}
          ></span>
        );
      }
    },
    {
      field: "postCategoryId",
      headerName: "Categorie",
      flex: 0.19,
      minWidth: 140,
      maxWidth: 170,
    },
    {
      field: "isImportantPost",
      headerName: "Importante",
      flex: 0.19,
      minWidth: 120,
      maxWidth: 150,
      renderCell: (params: GridRenderCellParams) =>
        params.row.postCategoryId == "Actualité" ? (
          params.value ? (
            <Tooltip title="oui">
              <CheckCircleIcon style={{ color: green[500] }} />
            </Tooltip>
          ) : (
            <Tooltip title="non">
              <DoNotDisturbOnIcon style={{ color: red[500] }} />
            </Tooltip>
          )
        ) : null,
    },
    {
      field: "isDraft",
      headerName: "Publiée",
      flex: 0.19,
      minWidth: 90,
      maxWidth: 120,
      renderCell: (params: GridRenderCellParams) =>
        params.value ? (
          <Tooltip title="non">
            <DoNotDisturbOnIcon style={{ color: red[500] }} />
          </Tooltip>
        ) : (
          <Tooltip title="oui">
            <CheckCircleIcon style={{ color: green[500] }} />
          </Tooltip>
        ),
    },
    {
      field: "isSuggestedPost",
      headerName: "Suggérée par",
      flex: 0.19,
      minWidth: 160,
      maxWidth: 160,
      renderCell: (params: GridRenderCellParams) =>
        params.value ? (
          <p>
            {params.row.createdBy?.firstname} {params.row.createdBy?.lastname}
          </p>
        ) : (
          ""
        ),
    },
    {
      field: "actions",
      headerName: "",
      width: 130,
      headerClassName: 'actionsColumnHeader',
      cellClassName: 'actionsColumnCell',
      renderCell: (params: any) => (
        <div>
          <IconButton>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={(event: any) => onDuplicateRowClick(params.row.id, event)}
          >
            <FileCopyIcon />
          </IconButton>
          <IconButton
            onClick={(event: any) => onDeleteRowClick(params.row.id, event)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  // fetch
  useEffect(() => {
    document.title = "MyJra - Gestion actualités";

    fetchAllPostsForTable();
    setIsNewPost(true);
  }, []);
  const fetchAllPostsForTable = async () => {
    try {
      const response = await Api.get("post/");
      const allPosts = response?.data;
      setPosts(allPosts);
      console.log("Données récupérées avec succès", response);
    } catch (error) {
      console.error("Erreur lors de la récupération des données");
    }
  };

  // set datas in table rows
  useEffect(() => {
    if (posts) {
      console.log(posts);
      const updatedRows = posts.map((post: Post) => {
        return {
          id: post.uid || "",
          title:
            post.postCategoryId == 2
              ? post.title + " " + post.subtitle
              : post.title,
          date: post.date ? new Date(post.date) : null,
          newEmployeeJob: post.newEmployeeJob,
          univers: post.univers?.name,
          salepoint: post.salepoint?.name,
          photo: post.photo,
          postCategoryId:
            post.postCategoryId == 1 ? "Actualité" : "Nouveau collaborateur",
          isImportantPost: post.isImportantPost,
          isDraft: post.isDraft,
          isSuggestedPost: post.isSuggestedPost,
          createdBy: post.createdBy,
        };
      });
      console.log('updatedRows:', updatedRows)
      setRows(updatedRows);
    }
  }, [posts]);
  // set datas in modal
  useEffect(() => {
    let children = ModalPost({
      errorPostMessages,
      handlePostChange,
      formPost,
      formRefPost,
    });
    setModalPostComponent(children);

    setTimeout(function () {
      setLoader(false);
    }, 1000);
  }, [
    formPost,
    errorPostMessages,
  ]);
  useEffect(() => {
    let childrenStats = ModalPostStats({
      errorPostStatsMessages,
      handlePostStatsChange,
      formPostStats,
      formRefPostStats,
    });
    setModalPostStatsComponent(childrenStats);

    setTimeout(function () {
      setLoader(false);
    }, 1000);
  }, [
    formPostStats,
    errorPostStatsMessages,
  ]);

  // Actions
  const onRowClick = (
    params: GridRowParams,
    event: MuiEvent,
    details: GridCallbackDetails
  ) => {
    const postId = params.id as string;
    setModalFormData(postId);
    setIsModalPostOpen(true);
  };
  const onDeleteRowClick = async (
    postUid: string,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setDisplayAlert({
      open: true,
      title: "Suppression",
      contentText: "Êtes-vous sûr de vouloir supprimer cette actualité ?",
      contentButtonSuccess: "Oui",
      contentButtonAbord: "Non",
      onSubmitSuccess: async () => {
        try {
          await Api.del("post/", { uid: postUid });
          const updatedPosts = posts.filter(
            (post: Post) => post.uid !== postUid
          );
          setPosts(updatedPosts);
          setNotif(renderNotif(true, "del", handleCloseNotif));
        } catch (error) {
          console.error("Erreur lors de la suppression de l'actualité", error);
          setNotif(renderNotif(false, "del", handleCloseNotif));
        } finally {
          setDisplayAlert(undefined);
        }
      },
      onSubmitAbord: () => setDisplayAlert(undefined),
    });
  };
  const onDuplicateRowClick = async (
    postUid: Post,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const response = await Api.post("post/duplicate", { uid: postUid });
      const newPost = response?.data;
      setPosts([...posts, newPost]);
      setNotif(renderNotif(true, "copy", handleCloseNotif));

      setModalFormData(newPost.uid);
      setIsModalPostOpen(true);
    } catch (error) {
      console.error("Erreur lors de la duplication de l'actualité", error);
      setNotif(renderNotif(false, "copy", handleCloseNotif));
    } finally {
      setDisplayAlert(undefined);
    }
  };
  const onCardClick = (postId: string) => {
    setModalFormData(postId);
    setIsModalPostOpen(true);
  };
  const onOpenModalPostClick = () => {
    setModalFormData();
    setIsModalPostOpen(true);
  };
  const onOpenModalPostStatsClick = () => {
    setModalFormStatsData();
    setIsModalPostStatsOpen(true);
  };
  const onDraftOrPublishModalClick = async (isDraft: boolean): Promise<boolean> => {
    const adjustedUrl = adjustUrl(formPost?.url);
    const adjustedUrlYoutube = adjustUrl(formPost?.urlYoutube);

    // si actualité, valider les url et effacer salepoint et newEmployeeJob
    // si nouvel employé, effacer les url
    const categorySpecFields =
      formPost?.postCategoryId === 1
        ? { url: adjustedUrl, urlYoutube: adjustedUrlYoutube, salepoint: undefined, newEmployeeJob: "" }
        : formPost?.postCategoryId === 2
          ? { url: "", urlYoutube: "" }
          : {};

    const updatedPostData = {
      ...formPost,
      isDraft: isDraft,
      createdBy: formPost?.createdBy?.uid,
      postCategoryId: formPost?.postCategoryId,
      ...categorySpecFields,
    };

    console.log('updatedPostData:', updatedPostData)

    if (handleFormPostErrors()) {
      let response;
      try {
        if (formPost?.uid) {
          response = await Api.put(`Post/`, updatedPostData);
        } else {
          response = await Api.post("Post/", updatedPostData);
        }
        const postDataVar = response?.data;
        handlePostChange("uid", response?.data.uid);
        onCloseModalClick();
        handleFormSubmitResponse(postDataVar);
        setNotif(renderNotif(true, "post", handleCloseNotif));
        console.log("Formulaire soumis avec succès", response);
        return true;
      } catch (error) {
        console.error(
          "Erreur lors de la soumission ou de la mise à jour du post",
          error
        );
        setNotif(renderNotif(false, "post", handleCloseNotif));
        return false;
      }
    } else {
      setNotif(renderNotif(false, "post", handleCloseNotif));
      return false;
    }
  };
  const onPublishStatsModalClick = async (): Promise<boolean> => {

    console.log('formPostStats:', formPostStats)

    if (handleFormPostStatsErrors()) {
      try {
        const response = await Api.put('PostStats/', formPostStats);
        // const postDataVar = response?.data;
        handlePostStatsChange("uid", response?.data.uid);
        onCloseModalClick();
        // handleFormSubmitResponse(postDataVar);
        setNotif(renderNotif(true, "post", handleCloseNotif));
        console.log("Formulaire soumis avec succès", response);
        return true;
      } catch (error) {
        console.error(
          "Erreur lors de la soumission ou de la mise à jour du post",
          error
        );
        setNotif(renderNotif(false, "post", handleCloseNotif));
        return false;
      }
    } else {
      setNotif(renderNotif(false, "post", handleCloseNotif));
      return false;
    }
  };
  const onCloseModalClick = async () => {
    resetFormState();
    setIsModalPostOpen(false);
    setIsModalPostStatsOpen(false);
    setErrorPostMessages({});
    setErrorPostStatsMessages({});
  };

  // Process datas after actions
  const setModalFormData = async (postUid?: string) => {
    let formData = {
      uid: "",
      title: "",
      subtitle: "",
      description: "",
      url: "",
      urlYoutube: "",
      photo: "",
      newEmployeeJob: "",
      newEmployeeSalepointUid: "",
      univers: {
        uid: "",
        name: ""
      },
      salepoint: {
        uid: "",
        name: ""
      },
      postCategoryId: 1,
      isImportantPost: false,
      likeNumber: 0,
      isDraft: false,
      isSuggestedPost: false,
      createdBy: null,
      date: "",
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
    };

    if (postUid) {
      setIsNewPost(false);
      let response;

      try {
        response = await Api.get("post/", "uid=" + postUid);
        console.log("Post récupéré avec succès", response);
      } catch (error) {
        console.error("Erreur lors de la récupération du post", error);
      }

      const postData = response?.data;
      formData = {
        uid: postData.uid,
        title: postData.title,
        subtitle: postData.subtitle,
        description: postData.description,
        url: postData.url,
        urlYoutube: postData.urlYoutube,
        photo: postData.photo,
        newEmployeeJob: postData.newEmployeeJob,
        newEmployeeSalepointUid: postData.newEmployeeSalepointUid,
        univers: postData.univers,
        salepoint: postData.salepoint,
        postCategoryId: postData.postCategoryId,
        isImportantPost: postData.isImportantPost,
        likeNumber: postData.likeNumber,
        isDraft: postData.isDraft,
        isSuggestedPost: postData.isSuggestedPost,
        createdBy: postData.createdBy,
        date: postData.date,
        createdAt: postData.createdAt,
        updatedAt: postData.updatedAt,
        deletedAt: postData.deletedAt,
      };
    } else {
      setIsNewPost(true);
    }
    setFormPost(formData);
  };
  const setModalFormStatsData = async () => {
    let formData = {
      uid: postStatsUid,
      titleOne: "",
      valueOne: "",
      explanationOne: "",
      titleTwo: "",
      valueTwo: "",
      explanationTwo: "",
      titleThree: "",
      valueThree: "",
      explanationThree: "",
      titleFour: "",
      valueFour: "",
      explanationFour: "",
      date: "",
      deletedAt: "",
    };

    let response;
    try {
      response = await Api.get("postStats/", "uid=" + formData["uid"]);
      console.log("Post récupéré avec succès", response);
    } catch (error) {
      console.error("Erreur lors de la récupération du post", error);
    }

    const postStatsData = response?.data;
    formData = {
      uid: postStatsData.uid,
      titleOne: postStatsData.titleOne,
      valueOne: postStatsData.valueOne,
      explanationOne: postStatsData.explanationOne,
      titleTwo: postStatsData.titleTwo,
      valueTwo: postStatsData.valueTwo,
      explanationTwo: postStatsData.explanationTwo,
      titleThree: postStatsData.titleThree,
      valueThree: postStatsData.valueThree,
      explanationThree: postStatsData.explanationThree,
      titleFour: postStatsData.titleFour,
      valueFour: postStatsData.valueFour,
      explanationFour: postStatsData.explanationFour,
      date: postStatsData.date,
      deletedAt: postStatsData.deletedAt,
    };

    setFormPostStats(formData);
  };
  const resetFormState = () => {
    setFormPost({
      uid: "",
      title: "",
      subtitle: "",
      description: "",
      url: "",
      urlYoutube: "",
      photo: "",
      newEmployeeJob: "",
      newEmployeeSalepointUid: "",
      univers: {
        uid: "",
        name: ""
      },
      salepoint: {
        uid: "",
        name: ""
      },
      postCategoryId: 1,
      isImportantPost: false,
      likeNumber: 0,
      isDraft: false,
      isSuggestedPost: false,
      createdBy: null,
      date: "",
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
    });
    setFormPostStats({
      uid: "",
      titleOne: "",
      valueOne: "",
      explanationOne: "",
      titleTwo: "",
      valueTwo: "",
      explanationTwo: "",
      titleThree: "",
      valueThree: "",
      explanationThree: "",
      titleFour: "",
      valueFour: "",
      explanationFour: "",
      date: "",
      deletedAt: "",
    });
  };
  const handlePostChange = (
    property: string,
    value: string | number | SalepointData | null
  ) => {

    console.log('property, value : ', property, value)

    setFormPost((prevFormPosts: any) => ({
      ...prevFormPosts,
      [property]: value,
    }));
  };
  const handlePostStatsChange = (
    property: string,
    value: string | number | SalepointData | null
  ) => {

    console.log('property, value : ', property, value)

    setFormPostStats((prevFormPosts: any) => ({
      ...prevFormPosts,
      [property]: value,
    }));
  };
  const handleFormSubmitResponse = (updatedData: Post) => {
    try {
      setPosts((prevPosts) => {
        const postIndex = prevPosts.findIndex(
          (post) => post.uid === updatedData.uid
        );
        if (postIndex !== -1) {
          const updatedPosts = prevPosts.map((post, index) =>
            index === postIndex ? { ...post, ...updatedData } : post
          );
          return updatedPosts;
        } else {
          return [updatedData, ...prevPosts];
        }
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour des données.", error);
    }
  };
  const handleCloseNotif = () => {
    setNotif(undefined);
  };
  const adjustUrl = (url: string | null | undefined): string | null => {
    if (!url) return null;

    // Si l'URL n'a pas de protocole, on ajoute "http://"
    if (!/^https?:\/\//i.test(url)) {
      url = "http://" + url;
    }
    return url;
  };
  const validateUrl = (url: string): boolean => {
    try {
      const parsedUrl = new URL(url);

      if (parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:") {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };
  const handleFormPostErrors = () => {
    const errors: Record<string, string> = {};

    const requiredString = "Requis";
    const invalidFormatString = "Url Youtube non valide";
    const lengthExceededString = "Longueur maximale dépassée";
    const noEmoticonsAllowedString = "Les émoticônes ne sont pas autorisées";

    const hasNoEmoticons = (text: string | null): boolean => {
      if (!text) return true;
      const regex = /[\p{Emoji_Presentation}|\p{Extended_Pictographic}]/u;
      return !regex.test(text);
    };

    if (formPost) {

      // titre obligatoire + < 100 + no emoticons
      if (!formPost.title) {
        errors.title = requiredString;
      } else {
        if (formPost.title.length > 100) {
          errors.title = lengthExceededString;
        }
        if (!hasNoEmoticons(formPost.title)) {
          errors.title = noEmoticonsAllowedString;
        }
      }
      // description obligatoire + < 100 + no emoticons
      if (!formPost.description) {
        errors.description = requiredString;
      } else {
        if (formPost.description.length > 1000) {
          errors.description = lengthExceededString;
        }
        if (!hasNoEmoticons(formPost.description)) {
          errors.description = noEmoticonsAllowedString;
        }
      }

      // Validation des deux URL
      if (formPost.postCategoryId == 1) {
        if (formPost.url && !validateUrl(formPost.url)) {
          errors.url = invalidFormatString;
        }
        if (formPost.urlYoutube && !validateUrl(formPost.urlYoutube)) {
          errors.urlYoutube = invalidFormatString;
        }
      }

      // photo, job et concess obligatoires pour nouvel employé
      if (formPost.postCategoryId == 2) {
        if (!formPost.photo) {
          errors.photo = requiredString;
        }

        if (!formPost.newEmployeeJob) {
          errors.newEmployeeJob = requiredString;
        }

        if (!formPost.newEmployeeSalepointUid) {
          errors.newEmployeeSalepointUid = requiredString;
        }
      }

      setErrorPostMessages(errors);
    }

    return Object.keys(errors).length === 0;
  };
  const handleFormPostStatsErrors = () => {
    const errors: Record<string, string> = {};

    const noEmoticonsAllowedString = "Emoji non autorisé";

    const hasNoEmoticons = (text: string | null): boolean => {
      if (!text) return true;
      const regex = /[\p{Emoji_Presentation}|\p{Extended_Pictographic}]/u;
      return !regex.test(text);
    };

    if (formPostStats) {

      console.log('formPostStats:', formPostStats)

      Object.keys(formPostStats).forEach((key) => {
        const value = formPostStats[key as keyof PostStats] as string;
        console.log('value:', value)

        if (value) {
          if (key.startsWith("title") && value.length > 35) {
            errors[key] = `Max 35 caractères`;
          }
          if (key.startsWith("value") && value.length > 10) {
            errors[key] = `Max 10 caractères`;
          }
          if (key.startsWith("explanation") && value.length > 255) {
            errors[key] = `Max 255 caractères`;
          }

          if (!hasNoEmoticons(value)) {
            errors[key] = noEmoticonsAllowedString;
          }
        }

      });

      setErrorPostStatsMessages(errors);
    }

    return Object.keys(errors).length === 0;
  };



  return loader ? (
    <Loader />
  ) : (
    <div id={style.accueilConfigPage} className="background">
      <h1>Gérer les actualités</h1>
      <div className="backsquare">
        <div className={`buttonBox ${style.buttonBox}`}>
          <div>
            <Link to="/toutes-actus" className="button">
              Aller à la page actualités
              <ListIcon />
            </Link>
          </div>
          <div className="buttonBox">
            <p
              className={`button ${style.button}`}
              onClick={() => onOpenModalPostStatsClick()}
            >
              Modifier les Chiffres
              <EditIcon />
            </p>
            <p
              className={`button ${style.button}`}
              onClick={() => onOpenModalPostClick()}
            >
              Nouvelle actualité
              <AddIcon />
            </p>
          </div>
        </div>

        {rows && rows.length > 0 ? (
          <div className={`${style.tableContainer} tableContainer`}>
            <TableChart
              columns={columns}
              data={rows}
              onRowClick={onRowClick}
              onCardClick={onCardClick}
              handleDeleteRow={onDeleteRowClick}
            />

            {displayAlert?.open === true ? <Alert {...displayAlert} /> : ""}
            {notif?.open === true ? <Notification {...notif} /> : ""}

            {<ModalFormComponent
              title={
                isNewPost
                  ? formPost?.postCategoryId == 1
                    ? "Nouvelle actualité"
                    : "Nouveau collaborateur"
                  : formPost?.postCategoryId == 1
                    ? "Modification de l'actualité"
                    : "Modification nouveau collaborateur"
              }
              modalOpen={isModalPostOpen}
              onClose={onCloseModalClick}
              onFormSubmitSuccess={onDraftOrPublishModalClick}
              childrenForm={modalPostComponent}
              formEvent={[
                () => onDraftOrPublishModalClick(true)
              ]}
              setErrorMessages={handleFormPostErrors}
              modalType="postConfig"
            />}

            {<ModalFormComponent
              title="Modification des Chiffres Groupe"
              modalOpen={isModalPostStatsOpen}
              onClose={onCloseModalClick}
              onFormSubmitSuccess={onPublishStatsModalClick}
              childrenForm={modalPostStatsComponent}
              formEvent={[
                () => onPublishStatsModalClick()
              ]}
              setErrorMessages={handleFormPostErrors}
              modalType="postConfigStats"
            />}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AccueilConfig;
