import style from '../law.module.scss';
import styleModal from '../../../../components/tinyModal/tinyModal.module.scss';
import { Autocomplete, Button, Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, TextField} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Role } from '../../../../services/object/Role';
import CheckIcon from "@mui/icons-material/Check";
import { Video } from '../../../../services/object/Video';

interface AppTinyPopupFormProps {
    ToRole: Role[],
    TOvideo: Video[],
    arrayRoleLabel: string[],
    setArrayRoleLabel: React.Dispatch<React.SetStateAction<string[]>>,
    handleButtonAddRoleVideo : (TOrole: Role[], videoUid: string) => Promise<void>
}
const AppTinyPopupForm = ({ToRole, arrayRoleLabel, setArrayRoleLabel, handleButtonAddRoleVideo, TOvideo} :AppTinyPopupFormProps) => {
    console.log(ToRole)
  const [localFormValuesRole, setLocalFormValuesRole] = useState(arrayRoleLabel)
  const [videoUid, setVideoUid] = useState<string>("")

  useEffect(() => {
  if (arrayRoleLabel && localFormValuesRole) {
      setArrayRoleLabel(localFormValuesRole);
  }
  }, [localFormValuesRole]);

  const handleChangeRole = (event: SyntheticEvent<Element, Event>, value: Role[]) => {
      setLocalFormValuesRole(value.map((role)=> (role. name)));
  };
  const handleChangeVideo = (event: string) => {
    setVideoUid(event);
  };
  return (
    <>
        <div className={style.tinyPopupSelectContainer}>
            <FormControl fullWidth>
            <InputLabel id="select-label">Sélectionner la vidéo</InputLabel>
            <Select
                labelId="select-label"
                id="select"
                label="Age"
                value={videoUid}
                onChange={(e) => handleChangeVideo(e.target.value as string)}
            >
                {TOvideo.map((video: Video)=>(
                <MenuItem value={video.uid}>{video.title}</MenuItem>
                ))}
            </Select>
            </FormControl>
        </div>
        <p className={styleModal.items_large}>Lier cette vidéo aux rôles concernés: </p>
        <div className={style.tinyPopupSelectContainer}>
            <FormControl style={{width:"100%"}}>
            <Autocomplete
                title={localFormValuesRole?.join(', ')}
                multiple
                className={style.autocomplete}
                limitTags={2}
                options={ToRole}
                disableCloseOnSelect
                getOptionLabel={(role :Role) => role.name}
                onChange={(e, value) => (handleChangeRole(e, value))}
                renderOption={(props, role, { selected = (localFormValuesRole?.indexOf(role.name) ?? -1 )> -1 }) => {
                    const { ...optionProps } = props;
                    return (
                    <li  {...optionProps}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            value={role.name}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={(localFormValuesRole?.indexOf(role.name) ?? -1) > -1 || selected}
                            />
                        {role.name}
                    </li>
                    );
                }}
                value={ToRole.filter((role:Role)=>{
                    if((localFormValuesRole?.indexOf(role.name) ?? -1) > -1) {
                        return role 
                    }
                })}
                renderInput={ params => {
                    const { InputProps, ...restParams } = params;
                    const { startAdornment, ...restInputProps } = InputProps;
                    return (
                    <TextField
                        required={(localFormValuesRole?.length === 0)}
                        name='role'
                        { ...restParams }
                        label="Role(s) concerné(s)"
                        InputProps={ {
                        ...restInputProps,
                        startAdornment: (
                            <div className={style.scrollDiv}>
                            {startAdornment}
                            </div>
                        ),
                        } }
                    />
                    );
                } }
                />
        </FormControl>
      </div>
      <div className="fullWidth">
            <Button variant="contained" onClick={() => (handleButtonAddRoleVideo((ToRole.filter((role: Role) => (localFormValuesRole.includes(role.name)))), videoUid))}
                    endIcon={<CheckIcon/>}> 
                Terminer
            </Button>
      </div>
    </>
  )
}
export default AppTinyPopupForm;