import React, { Fragment, useEffect, useState } from "react";
import style from './hierarchy.module.scss'

import { Service_Api } from '../../services/Api';
import { User } from "../../services/object/User";
import { Job } from "../../services/object/Job";
import { Salepoint } from "../../services/object/Salepoint";

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import noPhoto from '../Accueil/assets/images/noPhotoPost.png'

interface UserHirarachy {
    user: User,
    job: Job,
    salepoint: Salepoint,
    userCrew: boolean
}
interface children {
    data: UserHirarachy[]
}


function randomIntFromInterval(min: number, max: number) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const Chart = () => {
    const [TOUser, setTOUser] = useState<UserHirarachy[]>([]);
    const [currentUser, setCurrentUser] = useState<UserHirarachy>();
    const [data, setData] = useState<UserHirarachy[]>([]);

    const Api = Service_Api();

    async function fetchAppByUser(userUid = "6656e418c681d3.02759576") {
        let response = await Api.get('user/', 'uid=' + userUid);
        setCurrentUser(response?.data)

        response = await Api.get('user/getUserHierarchy', 'userUid=' + userUid);
        setData(response?.data)
    };

    function getGoogleProfileImage(email?: string) {
        if (email) {
            const emailHash = btoa(email);
            return `https://www.google.com/s2/u/0/photos/public/${emailHash}?sz=50`;
        }
        return noPhoto;
    }

    function Card({ data }: children) {
        return (
            <ul>
                <Fragment key={currentUser?.user.uid}>
                    <li>
                        <div className={style.card}>
                            <img
                                src={getGoogleProfileImage(currentUser?.user.email)}
                                alt="Profile"
                            />
                            <div className={style.card_body}>
                                <h4>{currentUser?.user.firstname} {currentUser?.user.lastname}</h4>
                                <p>{currentUser?.job.name}</p>
                                <p>{currentUser?.salepoint.name}</p>
                            </div>
                            <div className={style.card_footer}>
                                {(currentUser?.user?.managerUid) ? <button onClick={() => fetchAppByUser(currentUser?.user.managerUid)}>Monter</button> : ""}
                            </div>
                            <div></div>
                        </div>
                        {data.map((item: UserHirarachy, index: number) => (
                            <Fragment key={item.user.uid}>
                                <li>
                                    <div className={style.card}>
                                        <div className={style.image}>
                                            <img
                                                // Utilise la fonction pour chaque utilisateur avec fallback
                                                src={getGoogleProfileImage(item.user.email)}
                                                alt="Profile"
                                            />
                                        </div>
                                        <div className={style.card_body}>
                                            <h4>{item.user.firstname} {item.user.lastname}</h4>
                                            <p>{item.job.name}</p>
                                            <p>{item.salepoint.name}</p>
                                        </div>
                                        <div className={style.card_footer}>
                                            {(item.userCrew) ? <ArrowCircleDownIcon onClick={() => fetchAppByUser(item.user.uid)} /> : ""}
                                        </div>
                                        <div></div>
                                    </div>
                                </li>
                            </Fragment>
                        ))}
                    </li>
                </Fragment>
            </ul>
        );
    };


    useEffect(() => {
        setTOUser(data)
    }, [data])

    useEffect(() => {
        document.title = "MyJra - Organigramme";

        fetchAppByUser()
    }, [])


    return (
        <div className='background'>
            <h1>Organigramme</h1>
            <div className='backsquare'>
                <div className={style.org_tree}>
                    {
                        (TOUser && TOUser.length) ?

                            <Card data={TOUser} />
                            :
                            ""
                    }
                </div>
            </div>
        </div>
    );
};

export default Chart;
