import clsx from "clsx";
import { useState, useEffect, forwardRef } from "react";
import { Link, useLocation } from "react-router-dom";
import style from "./assets/scss/accueil.module.scss";

import { Service_Api } from "../../services/Api";
import { useAuth } from "../../services/auth/useAuth";

import { Post } from "../../services/object/Post";
import { UniversLogos } from "../../services/object/Univers";
import { formatWithLineBreaks } from "../../services/tools/formatWithLineBreak"
import { jsDateToLocalFr } from "../../services/tools/translateDate";

import modalStyle from "../../components/modal/modal.module.scss";
import noPhotoNews from "./assets/images/noPhotoPost.png";
import noPhotoNewEmployee from "./assets/images/noPhotoNewEmployee.jpg";

import { Modal } from "@mui/base/Modal";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";

interface PostModalProps {
  isOpen: boolean;
  onClose: () => void;
  post: Post;
  modalType: string;
  isLiked: boolean;
  likeNumber: number;
  onLikeClick: () => void;
}

export default function PostModal({
  isOpen,
  onClose,
  post,
  modalType,
  isLiked,
  likeNumber,
  onLikeClick,
}: PostModalProps) {

  if (!isOpen) return null;

  console.log('post:', post)

  const extractYouTubeId = (url: string): string => {
    const regExp =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|shorts\/|watch\?v=|watch\?.+&v=)|youtu\.be\/)([^#&?\/\n]+)(?:\S+)?/;
    const match = url.match(regExp);
    return match ? match[1] : "";
  };

  return (
    <div>
      <Modal
        id={modalType === "news" ? style.newsModal : modalType === "newEmployee" ? style.newEmployeeModal : ""}
        className={`${modalStyle.StyledModal} ${style.postModal}`}
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={isOpen}
        onClose={onClose}
        slots={{ backdrop: Backdrop }}
      >
        <div className={modalStyle.box}>
          <div className={style.modalHeader}>
            <Tooltip title="Fermer">
              <button className={modalStyle.close} onClick={onClose}>
                <CloseIcon />
              </button>
            </Tooltip>
            <span className={`${style.postCategory} ${modalType === "news" ? style.newsCategory : modalType === "newEmployee" ? style.newEmployeeCategory : ""}`}>
              {modalType === "news" ? "Actualité" : modalType === "newEmployee" ? "collaborateur" : ""}
            </span>
          </div>
          <div className={style.modalContent}>
            <div className={style.imageBox}>

              <div className={style.likeNumberBox}>
                <div
                  className={`${style.likeButton} ${isLiked ? style.isActive : ""}`}
                  onClick={onLikeClick}
                >
                  <ThumbUpIcon className={`${style.isLiked} ${style.bouncy}`} />
                  <ThumbUpOffAltIcon className={`${style.notLiked} ${style.bouncy}`} />
                  <span className={style.likeOverlay}></span>
                </div>
                <span className={style.likeNumber}>{likeNumber}</span>
              </div>

              {modalType === "newEmployee" ? (
                <img
                  src={post.photo ? post.photo : noPhotoNewEmployee}
                  alt="nouveau collaborateur"
                />
              ) : modalType === "news" ? (
                post.urlYoutube ? (
                  <div className={style.youtubeBox}>
                    <iframe
                      width="100%"
                      height="315"
                      src={`https://www.youtube.com/embed/${extractYouTubeId(
                        post.urlYoutube
                      )}`}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>

                  </div>
                ) : (
                  <img
                    src={post.photo ? post.photo : noPhotoNews}
                    alt="actualité"
                  />
                )
              ) : null}

              {post.univers ? (
                <img src={UniversLogos[post.univers.uid]} className={style.universLogo} alt="Univers" />
              ) : null}

              {modalType === "newEmployee" ? (
                <span className={style.overlayText}>{post.title} {post.subtitle}</span>
              ) : null}

            </div>
            <div className={style.highlighted}>
              {modalType === "newEmployee" ? (
                <>
                  <h2>
                    <span>Bienvenue {post.title}</span>
                    <span>{jsDateToLocalFr(new Date(post.date))}</span>
                  </h2>
                  {post.newEmployeeJob ? <h3>{post.newEmployeeJob}</h3> : null}
                  {post.salepoint ? <h3>{post.salepoint.name}</h3> : null}
                </>
              ) : (
                <>
                  <h2>    
                    <span>{post.title}</span>             
                    <span>{jsDateToLocalFr(new Date(post.date))}</span>
                  </h2>
                  <h3>{post.subtitle}</h3>
                </>
              )}


            </div>
            {post.description ? (
              <p className={`${style.description} autoScrollVertical`}>
                {formatWithLineBreaks(post.description)}
              </p>
            ) : null}

            <div className={`buttonBox ${style.buttonBox}`}>
              {post.url && (
                <Link
                  to={post.url}
                  className={`button smallButton ${style.button} ${style.outsideLink}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Plus d'infos
                  <OpenInNewIcon />
                </Link>
              )}

              <Link
                to={"https://www.linkedin.com/feed/?linkOrigin=LI_BADGE&shareActive=true&" + ((post.url) ? "shareUrl=" + post.url : "") + "&text=" + post.description}
                className={`button smallButton ${style.button} ${style.outsideLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Partager sur Linkedin
                <LinkedInIcon />
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const Backdrop = forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, modalStyle.Backdrop)}
      ref={ref}
      {...other}
    />
  );
});
