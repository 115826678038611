
export interface PostStats {
  uid: string,
  titleOne: string,
  valueOne: string,
  explanationOne: string,
  titleTwo: string,
  valueTwo: string,
  explanationTwo: string,
  titleThree: string,
  valueThree: string,
  explanationThree: string,
  titleFour: string,
  valueFour: string,
  explanationFour: string,
  date: Date | string,
  deletedAt: Date | string,
}

export const postStatsUid = "66fc031cb29a80.53110338"