import { SyntheticEvent, useEffect, useState } from 'react';
import { Role } from '../../../../services/object/Role';
import { Video } from '../../../../services/object/Video';
import AppTinyPopupForm from './AppTinyPopupForm';

interface AppTinyPopupChildProps {
    ToRole: Role[],
    TOvideo: Video[],
    arrayRoleLabel: string[],
    setArrayRoleLabel: React.Dispatch<React.SetStateAction<string[]>>,
    handleButtonAddRoleVideo : (TOrole: Role[], videoUid: string) => Promise<void>
}
const AppTinyPopupChild = ({ToRole, arrayRoleLabel, setArrayRoleLabel, handleButtonAddRoleVideo, TOvideo} :AppTinyPopupChildProps) => {

  return (
    <AppTinyPopupForm ToRole={ToRole}
                      arrayRoleLabel={arrayRoleLabel}
                      setArrayRoleLabel={setArrayRoleLabel}
                      handleButtonAddRoleVideo={handleButtonAddRoleVideo}
                      TOvideo={TOvideo} />
  )
}
export default AppTinyPopupChild;