import React, { useState, useEffect, ChangeEvent } from "react";

import style from "../styles/accueilConfig.module.scss";
import styleTinyModal from "../../../../components/tinyModal/tinyModal.module.scss";

import { Loader } from "../../../../components/loader/Loader";
import TinyModalComponent from "../../../../components/tinyModal/TinyModalComponent";
import { PostStats } from "../../../../services/object/PostStats";

import 'dayjs/locale/fr';

import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

interface FormPostStatsProps {
  onChange: (property: string, value: string) => void;
  postStats: PostStats | undefined;
  errorPostStatsMessages: Record<string, string>;
}

const FormPostStats = ({
  onChange,
  postStats,
  errorPostStatsMessages,
}: FormPostStatsProps) => {
  const [loader, setLoader] = useState<boolean>(true);
  const [localFormValues, setLocalFormValues] = useState({
    titleOne: postStats?.titleOne ?? "",
    valueOne: postStats?.valueOne ?? "",
    explanationOne: postStats?.explanationOne ?? "",
    titleTwo: postStats?.titleTwo ?? "",
    valueTwo: postStats?.valueTwo ?? "",
    explanationTwo: postStats?.explanationTwo ?? "",
    titleThree: postStats?.titleThree ?? "",
    valueThree: postStats?.valueThree ?? "",
    explanationThree: postStats?.explanationThree ?? "",
    titleFour: postStats?.titleFour ?? "",
    valueFour: postStats?.valueFour ?? "",
    explanationFour: postStats?.explanationFour ?? "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (postStats) {
      setLocalFormValues({
        titleOne: postStats?.titleOne ?? "",
        valueOne: postStats?.valueOne ?? "",
        explanationOne: postStats?.explanationOne ?? "",
        titleTwo: postStats?.titleTwo ?? "",
        valueTwo: postStats?.valueTwo ?? "",
        explanationTwo: postStats?.explanationTwo ?? "",
        titleThree: postStats?.titleThree ?? "",
        valueThree: postStats?.valueThree ?? "",
        explanationThree: postStats?.explanationThree ?? "",
        titleFour: postStats?.titleFour ?? "",
        valueFour: postStats?.valueFour ?? "",
        explanationFour: postStats?.explanationFour ?? "",
      });
    }

    setTimeout(function () {
      setLoader(false);
    }, 500);
  }, [postStats]);

  const handleLocalChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLocalFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    onChange(name, value);
  };
  const handleReset = () => {
    setIsModalOpen(true);
  };
  const handleConfirmReset = () => {
    setLocalFormValues({
      titleOne: "",
      valueOne: "",
      explanationOne: "",
      titleTwo: "",
      valueTwo: "",
      explanationTwo: "",
      titleThree: "",
      valueThree: "",
      explanationThree: "",
      titleFour: "",
      valueFour: "",
      explanationFour: "",
    });
    setIsChecked(true);
    setIsModalOpen(false);
  };
  const handleCloseModal = () => {
    setIsChecked(false);
    setIsModalOpen(false);
  };


  return loader ? (
    <Loader position={"form"} />
  ) : (
    <div className={style.statsContent}>
      <div className={style.header}>
        <h3>
          Vous pouvez afficher de zero à quatre statistiques. <br/> 
          Pour chaque stat, veuillez indiquer au minimum le titre et la valeur.
        </h3>
        <button className="button" onClick={handleReset}>
          Tout vider
        </button>
      </div>
      <div className={`${style.column} ${style.content}`}>
        <div className={style.row}>
          <div className={`${style.postStat} ${style.column}`}>
            <div className={style.row}>
              <TextField
                fullWidth
                label="Titre 1"
                size="small"
                name="titleOne"
                value={localFormValues.titleOne}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                error={!!errorPostStatsMessages.titleOne}
                helperText={errorPostStatsMessages.titleOne}
                className={style.input}
              />
              <TextField
                fullWidth
                label="Valeur 1"
                size="small"
                name="valueOne"
                value={localFormValues.valueOne}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                error={!!errorPostStatsMessages.valueOne}
                helperText={errorPostStatsMessages.valueOne}
                className={style.input}
              />
            </div>
            <TextField
              fullWidth
              label="Explication 1"
              size="small"
              name="explanationOne"
              value={localFormValues.explanationOne}
              onChange={handleLocalChange}
              onBlur={handleBlur}
              error={!!errorPostStatsMessages.explanationOne}
              helperText={errorPostStatsMessages.explanationOne}
              className={style.input}
            />
          </div>

          <div className={`${style.postStat} ${style.column}`}>
            <div className={style.row}>
              <TextField
                fullWidth
                label="Titre 2"
                size="small"
                name="titleTwo"
                value={localFormValues.titleTwo}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                error={!!errorPostStatsMessages.titleTwo}
                helperText={errorPostStatsMessages.titleTwo}
                className={style.input}
              />
              <TextField
                fullWidth
                label="Valeur 2"
                size="small"
                name="valueTwo"
                value={localFormValues.valueTwo}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                error={!!errorPostStatsMessages.valueTwo}
                helperText={errorPostStatsMessages.valueTwo}
                className={style.input}
              />
            </div>
            <TextField
              fullWidth
              label="Explication 2"
              size="small"
              name="explanationTwo"
              value={localFormValues.explanationTwo}
              onChange={handleLocalChange}
              onBlur={handleBlur}
              error={!!errorPostStatsMessages.explanationTwo}
              helperText={errorPostStatsMessages.explanationTwo}
              className={style.input}
            />
          </div>
        </div>

        <div className={style.row}>
          <div className={`${style.postStat} ${style.column}`}>
            <div className={style.row}>
              <TextField
                fullWidth
                label="Titre 3"
                size="small"
                name="titleThree"
                value={localFormValues.titleThree}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                error={!!errorPostStatsMessages.titleThree}
                helperText={errorPostStatsMessages.titleThree}
                className={style.input}
              />
              <TextField
                fullWidth
                label="Valeur 3"
                size="small"
                name="valueThree"
                value={localFormValues.valueThree}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                error={!!errorPostStatsMessages.valueThree}
                helperText={errorPostStatsMessages.valueThree}
                className={style.input}
              />
            </div>
            <TextField
              fullWidth
              label="Explication 3"
              size="small"
              name="explanationThree"
              value={localFormValues.explanationThree}
              onChange={handleLocalChange}
              onBlur={handleBlur}
              error={!!errorPostStatsMessages.explanationThree}
              helperText={errorPostStatsMessages.explanationThree}
              className={style.input}
            />
          </div>

          <div className={`${style.postStat} ${style.column}`}>
            <div className={style.row}>
              <TextField
                fullWidth
                label="Titre 4"
                size="small"
                name="titleFour"
                value={localFormValues.titleFour}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                error={!!errorPostStatsMessages.titleFour}
                helperText={errorPostStatsMessages.titleFour}
                className={style.input}
              />
              <TextField
                fullWidth
                label="Valeur 4"
                size="small"
                name="valueFour"
                value={localFormValues.valueFour}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                error={!!errorPostStatsMessages.valueFour}
                helperText={errorPostStatsMessages.valueFour}
                className={style.input}
              />
            </div>
            <TextField
              fullWidth
              label="Explication 4"
              size="small"
              name="explanationFour"
              value={localFormValues.explanationFour}
              onChange={handleLocalChange}
              onBlur={handleBlur}
              error={!!errorPostStatsMessages.explanationFour}
              helperText={errorPostStatsMessages.explanationFour}
              className={style.input}
            />
          </div>
        </div>
      </div>

      <TinyModalComponent
        onClose={handleCloseModal}
        modalOpen={isModalOpen}
        title=""
        progess={false}
        child={
          <div className={styleTinyModal.items}>
            <h2>Cette action va supprimer toutes les statistiques. Voulez-vous continuer ?</h2>
            <div className="buttonBox">
              <button className="button" onClick={handleCloseModal}>Annuler</button>
              <button className="button" onClick={handleConfirmReset}>Oui</button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default FormPostStats;
