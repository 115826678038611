import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import style from './input.module.scss';
import { useState } from 'react';

interface CheckboxProps {
  legend: string;
  setCheckedObject: React.Dispatch<React.SetStateAction<string[]>>;
  Objet: any;
  checkedObject: string[];
  errorMessages: Record<string, string>;
  required: boolean;
  isSoloCheckbox?: boolean;
  customEvent?: (params?: any) => void;
}

export default function CheckboxOnly({ Objet, legend, setCheckedObject, checkedObject, errorMessages, required, isSoloCheckbox, customEvent }: CheckboxProps) {

  const [someChecked, setSomeChecked] = useState(checkedObject.length > 0);

  const handleRolechange = (e: React.SyntheticEvent<Element, Event> ,objectUid : string) => {
    let target = e.target as HTMLInputElement;
    let updatedcheckedObject: string[]
    if (target.checked) {
      updatedcheckedObject = [...checkedObject, objectUid]
    } else {
      updatedcheckedObject = checkedObject.filter(uid => uid !== objectUid);
    };
    setSomeChecked(updatedcheckedObject.length > 0)
    setCheckedObject(updatedcheckedObject);
    if(customEvent !== undefined)
      customEvent(updatedcheckedObject);
  } 
  let errorName = "";
  return (
    <div className={style.containercheck}>
      <div className={`${style.uicheckbox} ${isSoloCheckbox ? style.soloCheckbox : ''} `}>
        <FormLabel required={(someChecked || checkedObject.length > 0 || !required) ? false : required} component="legend" error={(checkedObject.length === 0 && Object.keys(errorMessages).length !== 0)}>{legend}</FormLabel>
          
            <FormGroup sx={{flexDirection: 'column'}}>
            {Objet.map((object: any) => {
                errorName = ((checkedObject.length > 0) ? "" : object.name)
              return (<FormControlLabel
                control={<Checkbox name={object.name} value={object.uid} checked={checkedObject.includes(object.uid)}/>}
                key={object.uid}
                label={object.name} 
                name={object.name}
                onChange={(e) => handleRolechange(e, object.uid)}
                required={(someChecked || checkedObject.length > 0 || !required) ? false : required}
              />)
              })}
            </FormGroup>
          
        </div>
        <div className={style.errorMessage}> {errorMessages[errorName]}</div>
      </div>
  );
};
