import * as React from 'react';
import FormUser from './FormUser';
import style from './Utilisateur.module.scss'
import Checkbox from '../../../components/input/Checkbox';
import CheckboxGroup from '../../../components/input/CheckboxGroup';
import Summary from './Summary';
import ControllableStates from '../../../components/input/ControllableStates'
import { User } from '../../../services/object/User';
import { Salepoint } from '../../../services/object/Salepoint';
import { ChildProps, UserData } from './Utilisateur';
import { Job } from '../../../services/object/Job';
import { App } from '../../../services/object/App';
import RadioOnly from '../../../components/input/RadioOnly';
import { Law } from '../../../services/object/Law';
import StepperLinear from '../../../components/StepperLinear/StepperLinear';
import { Role } from '../../../services/object/Role';

interface ModalUserProps {
  errorMessages: Record<string, string>,
  handleUserChange: (property: string, value: string) => void,
  formUsers: User | undefined,
  checkedRole: string[],
  checkedJraApp: string[],
  setCheckedJraApp: React.Dispatch<React.SetStateAction<string[]>>,
  checkedNoJraApp: string[],
  setCheckedNoJraApp: React.Dispatch<React.SetStateAction<string[]>>,
  checkedSalepoint: string[],
  checkedUnivers: string[],
  checkedLaw: string [],
  formRefUser: React.RefObject<HTMLFormElement>,
  formRefJob: React.RefObject<HTMLFormElement>,
  formRefApp: React.RefObject<HTMLFormElement>,
  formRefRole: React.RefObject<HTMLFormElement>,
  job: Job[],
  jraApps: App[],
  noJraApps: App[],
  univers: string[],
  T_O_salepoint: Salepoint[],
  childCheckedSalepoint: ChildProps[],
  setChildCheckedSalepoint: React.Dispatch<React.SetStateAction<ChildProps[]>>,
  lawAndRoleData: { role: Role[]; law: Law[] },
  childCheckedLaw: ChildProps[],
  setChildCheckedLaw: React.Dispatch<React.SetStateAction<ChildProps[]>>,
  activeStep?: { index: number, appUid: string },
  setActiveStep: React.Dispatch<React.SetStateAction<{ index: number, appUid: string }| undefined>>,
  handleSaveRoleAndLaw: () => Promise<boolean>,
  stepperData?: App[],
  userData: UserData,
  jraAppData?: App[],
  noJraAppData?: App[],
}

export default function ModalUser({
    childCheckedLaw,
    setChildCheckedLaw,
    errorMessages,
    handleUserChange,
    formUsers,
    checkedRole,
    setChildCheckedSalepoint,
    checkedSalepoint,
    checkedUnivers,
    checkedLaw,
    formRefUser,
    formRefJob,
    formRefApp,
    formRefRole,
    job,
    jraApps,
    noJraApps,
    univers,
    T_O_salepoint,
    childCheckedSalepoint,
    setCheckedJraApp,
    checkedJraApp,
    setCheckedNoJraApp,
    checkedNoJraApp,
    lawAndRoleData,
    activeStep,
    setActiveStep,
    handleSaveRoleAndLaw,
    stepperData,
    userData,
}: ModalUserProps) {

  const onChangeJob = (jobUid: string) => {
    handleUserChange("jobUid", jobUid)
  };


  let tfrom = [ 
              <>
                  <form className={style.uiform} ref={formRefUser} >
                    <FormUser onChange={handleUserChange} formUsers={formUsers} errorMessages={errorMessages} />
                  </form>
                </>
                ,
              <>
                <form className={style.uiform} ref={formRefJob}>
                  <div >
                    <ControllableStates
                        Objet={job}
                        legend="Métier"
                        onChange={onChangeJob}
                        selecteUid={formUsers?.jobUid ?? ""}
                        errorMessages={errorMessages}
                      />
                      <CheckboxGroup  
                        objetParent={univers}
                        objetChild={T_O_salepoint} 
                        objectParentName="univers"
                        legend="Point de Vente"
                        childChecked={childCheckedSalepoint}
                        setChildChecked={setChildCheckedSalepoint}
                        checkedOfChild={checkedSalepoint}
                        checkedOfParent={checkedUnivers}
                        errorMessages={errorMessages}
                        required={true}
                      />                        
                  </div>
                  </form>
                  </>
                ,
              <>
                <form className={style.uiform} ref={formRefApp}>
                  <div className={style.checkContenair}>
                      <Checkbox
                        Objet={jraApps} 
                        legend="Appcations interne"
                        setCheckedObject={setCheckedJraApp}
                        checkedObject={checkedJraApp}
                        errorMessages={errorMessages}
                        required={true}
                      />
                      <Checkbox
                        Objet={noJraApps} 
                        legend="Autres applications"
                        setCheckedObject={setCheckedNoJraApp}
                        checkedObject={checkedNoJraApp}
                        errorMessages={errorMessages}
                        required={true}
                      />
                    </div>
                  </form>
                  </>
                ,
              <>
                <form className={style.uiform} ref={formRefRole}>
                  <StepperLinear handleSaveStepper={handleSaveRoleAndLaw} handleNextStepper={handleSaveRoleAndLaw} activeStep={activeStep} setActiveStep={setActiveStep} data={stepperData ?? []}>
                    <div className={style.checkContenairAlone}>
                      <CheckboxGroup  
                        objetParent={lawAndRoleData.role}
                        objetChild={lawAndRoleData.law}
                        objectParentName="role"
                        legend="Quel Rôle aura l'utilisateur?"
                        childChecked={childCheckedLaw}
                        setChildChecked={setChildCheckedLaw}
                        checkedOfChild={checkedLaw}
                        checkedOfParent={checkedRole}
                        errorMessages={errorMessages}
                        required={true}
                        isSoloCheckbox={true}
                      />
                    </div>
                  </StepperLinear>
                </form>
              </>
              ,
              <>
              <form className={style.uiform}>
                <Summary formUsers={formUsers} userData={userData}/>
              </form>
            </>
        ];

        if(lawAndRoleData.role.length <= 0 && lawAndRoleData.law.length <= 0){
          tfrom[3] = tfrom[4];
          tfrom.pop();
        }
  return (tfrom);
} 