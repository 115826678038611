
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import style from './openningHours.module.scss';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { OpenningHours } from '../../../../services/object/OpenningHours';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import {Service_Api} from '../../../../services/Api';
import { useAuth } from '../../../../services/auth/useAuth';

interface OpenningHoursItemProps {
    openningHours: OpenningHours;
    firstOfDay:boolean;
    menuItemList: () => JSX.Element[];
    handleFormHoursChange:(uid: string, property: keyof OpenningHours, value: never) => void;
    addOpenningHours: (newopenningHours: OpenningHours) => Promise<void>;
    removeOpenningHours: (newopenningHours: OpenningHours) => Promise<void>;
  }
function dayToString(dayNumber:number){
	let days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
    return days[dayNumber];
}
function OpenningHoursItem({
    openningHours, 
    menuItemList, 
    handleFormHoursChange, 
    addOpenningHours,
    removeOpenningHours,
    firstOfDay}: OpenningHoursItemProps) {
    const [isDeleted, setIsDeleted] = useState(false);
    const { user } = useAuth();
    const Api = Service_Api();
    const onClickHandlerAdd = async () => {
        let data = { 
            day : openningHours.day,
            hourStart : "08:00",
            hourEnd :  "18:00",
            userUid : user.uid,
            salepointUid : openningHours.salepointUid,
            isAdditionnal : true,
        } as OpenningHours

        addOpenningHours(data);
    };

    return (
        <>
            { (!isDeleted) ? 
            <>
                <li >
                    <div className={style.itemContainer}>
                    
                        <span className={style.day}> { ((firstOfDay) ? dayToString(Number(openningHours.day)) : "") }</span>
                        <FormControl className={style.inputContainer} sx={{ m: 1, minWidth: 150}}>
                            <InputLabel id="demo-simple-select-standard-label">Ouverture</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={openningHours?.hourStart}
                                onChange={e => handleFormHoursChange(openningHours?.uid, "hourStart", e.target.value as never)}
                                label="Ouverture">
                            {
                                menuItemList().map(
                                    (element) => {
                                        return element;
                                })
                                }
                            </Select>
                        </FormControl>
                        <FormControl className={style.inputContainer} sx={{ m: 1, minWidth: 150}}>
                            <InputLabel id="demo-simple-select-standard-label">Fermeture</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={openningHours?.hourEnd}
                                onChange={e => handleFormHoursChange(openningHours?.uid, "hourEnd", e.target.value as never)}
                                label="Fermeture">
                                {
                                menuItemList().map(
                                    (element) => {
                                        return element;
                                })
                                }
                            </Select>
                        </FormControl>
                        {
                        (firstOfDay === true) ? 
                            <div className={style.penContent} >
                                <Fab color="primary" aria-label="add">
                                    <AddIcon onClick={onClickHandlerAdd}/>
                                </Fab>
                            </div>
                        : 
                        (openningHours?.isAdditionnal) ? 
                            <IconButton className={style.icon} onClick={(e) => removeOpenningHours(openningHours)}><DeleteIcon/></IconButton>
                        : 
                            <span className={style.fkDayButton}></span>
                        }
                        
                    </div>
                </li>
            </>
            : ""
            }
        </>
      );
}


export default OpenningHoursItem;