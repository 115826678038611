import React, { useState, useEffect, ChangeEvent } from "react";

import style from '../law.module.scss';
import MultiSelect from './MultiSelect';

import { App } from '../../../../services/object/App';
import { Job } from '../../../../services/object/Job';
import { Univers } from '../../../../services/object/Univers';
import { Salepoint } from '../../../../services/object/Salepoint';
import { getBase64 } from "../../../../services/tools/imgToBase64";

import noPhoto from "../../../../components/photoUpload/noPhoto.png";
import photoUlploadStyle from '../../../../components/photoUpload/photoUpload.module.scss';
import RadioOnly from '../../../../components/input/RadioOnly';

import 'dayjs/locale/fr';

import { Button, TextField } from '@mui/material';

import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AddIcon from '@mui/icons-material/Add';


interface FormAppProps {
  errorMessages: Record<string, string>,
  app: App | undefined,
  handleFormAppChange: (property: string, value: string) => void
  ToJob: Job[]
  arrayJobLabel: [string[]] | undefined
  arrayUniversLabel: [string[]] | undefined
  TOSalepoint: Salepoint[]
  arraySalepointLabel: [string[]] | undefined
  setArraySalepointLabel: React.Dispatch<React.SetStateAction<[string[]]| undefined>>
  setArrayUniversLabel: React.Dispatch<React.SetStateAction<[string[]]| undefined>>
  setArrayJobLabel: React.Dispatch<React.SetStateAction<[string[]]| undefined>>
  TOUnivers: Univers[]
  setExceptionNumber: React.Dispatch<React.SetStateAction<number>>
  exceptionNumber: number
}

const FormApp = ({
  errorMessages,
  app,
  ToJob,
  arrayJobLabel,
  TOSalepoint,
  arraySalepointLabel,
  setArraySalepointLabel,
  setArrayJobLabel,
  TOUnivers,
  arrayUniversLabel,
  setArrayUniversLabel,
  handleFormAppChange,
  setExceptionNumber,
  exceptionNumber
}: FormAppProps) => {

  const [selectedImage, setSelectedImage] = useState<string | null>();
  const MAX_IMAGE_SIZE_MB = 3 * 1024 * 1024;
  const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png"];
  const [localFormValues, setLocalFormValues] = useState({
    name: app?.name ?? "",
    url: app?.url ?? "",
  });
  useEffect(() => {
    if (app) {
      console.log('FormApp app:', app)
      setLocalFormValues({
        name: app.name ?? "",
        url: app.url ?? "",
      });
    }
  }, [app]);
  
  const onDelete = () => {
    setExceptionNumber((exception) => exception - 1)
  }
  let allSelect = [];
  let tempArrayJob = arrayJobLabel
  let tempArraySalepoint = arraySalepointLabel
  let tempArrayUnivers = arrayUniversLabel
  for (let index = 0; index < exceptionNumber; index++) {
    if (tempArrayJob && tempArrayJob.length !== exceptionNumber)
      tempArrayJob.push([])
    if (tempArrayUnivers && tempArrayUnivers.length !== exceptionNumber)
      tempArrayUnivers.push([])
    if (tempArraySalepoint && tempArraySalepoint.length !== exceptionNumber)
      tempArraySalepoint.push([])
    allSelect.push(<MultiSelect arrayJobLabel={tempArrayJob}
      ToJob={ToJob}
      arrayUniversLabel={tempArrayUnivers}
      TOUnivers={TOUnivers}
      arraySalepointLabel={tempArraySalepoint}
      TOSalepoint={TOSalepoint}
      errorMessages={errorMessages}
      setArraySalepointLabel={setArraySalepointLabel}
      setArrayUniversLabel={setArrayUniversLabel}
      setArrayJobLabel={setArrayJobLabel}
      exceptionNumber={index}
      canBeDeleted={index+1 === exceptionNumber}
      onDelete={onDelete}/>)
  } 

  const onChangeJraApp = (value: string) => {
    handleFormAppChange('isJraApp', value)
  }
  const handleLocalChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLocalFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    handleFormAppChange(name, value);
  };

  const addException = () => {
    setExceptionNumber((exception) => exception + 1)
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.type && ALLOWED_IMAGE_TYPES.includes(file.type)) {
        if (file.size > MAX_IMAGE_SIZE_MB) {
          alert(
            "L'image sélectionnée dépasse la taille maximale autorisée de 3 Mo."
          );
          return;
        }
        setSelectedImage(URL.createObjectURL(file));
        getBase64(file, (result: string) => {
          handleFormAppChange("photo", result);
        });
      } else {
        alert("Veuillez choisir une image de type [.jpeg] ou [.png].");
      }
    } else {
      setSelectedImage(null);
    }
  };
  const handleCircleClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <div className={style.contentText}>
      <TextField fullWidth label="Nom"
        value={localFormValues.name}
        size="medium"
        name="name"
        onChange={handleLocalChange}
        onBlur={handleBlur}
        required
        error={!!errorMessages.name}
        helperText={errorMessages.name} />
      <TextField fullWidth label="Url"
        value={localFormValues.url}
        size="medium"
        name="url"
        onChange={handleLocalChange}
        onBlur={handleBlur}
        required
        error={!!errorMessages.url}
        helperText={errorMessages.url} />
      <RadioOnly
        Objet={[{ "uid": "0", "name": "Non" }, { "uid": "1", "name": "Oui" }]}
        legend="Est une application interne (dev maison)"
        onChange={onChangeJraApp}
        checkedUid={((app?.isJraApp?.toString()) === "true" || (app?.isJraApp?.toString()) === "1") ? "1" : "0"}
        errorMessages={errorMessages}
        required={true} />


      <div className={photoUlploadStyle.photoPostBox}>
        <div
          className={`${photoUlploadStyle.photoPost} ${selectedImage ? photoUlploadStyle.active : ""}`}
          onClick={handleCircleClick}
          style={{
            backgroundImage: (
              selectedImage ? `url(${selectedImage})` : app?.photo
            )
              ? `url(${app?.photo})`
              : `url(${noPhoto})`,
          }}
        >
          <CreateOutlinedIcon />
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          id="fileInput"
          name="photo"
        />
        {errorMessages.photo && (
          <p className={photoUlploadStyle.errorMessage}>{errorMessages.photo}</p>
        )}
      </div>

      {allSelect.map((elem) => (elem))}

      <div className={style.fullWidth}>
        <Button className='addbutton'
          variant="contained"
          sx={{ width: 230 }}
          onClick={addException}
          endIcon={<AddIcon />}>
          Nouvelle attribution
        </Button>
      </div>
    </div>
  );
};

export default FormApp;
