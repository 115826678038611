import style from './salepoint.module.scss';
import styleOpenningHours from './openningHours/openningHours.module.scss';
import styleModal from '../../../components/modal/modal.module.scss';
import { Univers } from '../../../services/object/Univers';
import React, { SyntheticEvent, useEffect } from 'react';
import CheckboxOnly from '../../../components/input/Checkbox';
import { Salepoint } from '../../../services/object/Salepoint';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Button, Checkbox, FormControl, FormHelperText, MenuItem, SpeedDialIcon, TextField } from '@mui/material';
import { OpenningHoursException } from '../../../services/object/OpenningHoursException';
import OpenningHoursExeption from './openningHours/OpenningHoursException';
import uuidv4 from '../../../services/tools/uuid';
import { dateJstoSqlDate } from '../../../services/tools/translateDate';

interface SalepointPopupChildrensProps {
    errorMessages: Record<string, string>;
    formRefHours: React.RefObject<HTMLFormElement>;
    universData : Univers[];
    salepointData : Salepoint[];
    checkedUniversHours: string[];
    setCheckedUniversHours: React.Dispatch<React.SetStateAction<string[]>>;
    salepointLabel:string[];
    setSalepointLabel: React.Dispatch<React.SetStateAction<string[]>>;
    openningHoursException:  OpenningHoursException[]|undefined;
    handleFormHoursExceptionChange:  (uid: string, property: keyof OpenningHoursException, value: never) => void;
    setOpenningHoursException: React.Dispatch<React.SetStateAction<OpenningHoursException[] | undefined>>;

  }
    const salepointPopupChildrens = ({
                                      errorMessages,
                                      formRefHours,
                                      universData,
                                      salepointData,
                                      checkedUniversHours,
                                      setCheckedUniversHours,
                                      salepointLabel,
                                      setSalepointLabel,
                                      openningHoursException,
                                      handleFormHoursExceptionChange,
                                      setOpenningHoursException} :SalepointPopupChildrensProps) => {
    
    const handleChangSalepoint= (event: SyntheticEvent<Element, Event>, value: Salepoint[]) => {
        let Tsalepoint = salepointData.filter((salepoint : Salepoint) => { 
            return value.includes(salepoint)
        })
        Tsalepoint.concat(salepointData.filter((salepoint : Salepoint) => { 
            return checkedUniversHours.includes(salepoint.universUid) ?? value.includes(salepoint)
        }))
        setSalepointLabel(Tsalepoint.map((salepoint :Salepoint) => {return salepoint.name}))
    };

    const handleChangUnivers= (checkedUnivers:string[]) => {
        setCheckedUniversHours(checkedUnivers)
        setSalepointLabel(salepointData.filter((salepoint : Salepoint) => ( checkedUnivers.includes(salepoint.universUid) ?? salepointLabel.includes(salepoint.name))).map((salepoint :Salepoint) => {return salepoint.name}))
    };

    // const { user } = useAuth();
    // const Api = Service_Api();
        
    const menuItemList = () => {
        let maxHours = 24;
        let list = [];
        for (let iindex = 0; iindex < maxHours; iindex++) {
            list.push(<MenuItem value={("0"+iindex ).slice(-2)+":00"}>{("0"+iindex ).slice(-2) +":00"}</MenuItem>)
            list.push(<MenuItem value={("0"+iindex ).slice(-2)+":30"}>{("0"+iindex ).slice(-2) +":30"}</MenuItem>)
        }
        return list;
    }
    
    const onClickHandlerAdd = async () => {
        let date = new Date((new Date()).setDate((new Date()).getDate() + 1))
        let newopenningHoursException = {
            uid: uuidv4(),
            date: dateJstoSqlDate(date),
            hourStart:"08:00",
            hourEnd:"18:00",
            userUid: "",
            salepointUid: "",
            isAdditionnal: false,
            isClose: true,
        } as OpenningHoursException
        setOpenningHoursException([
            ...openningHoursException ?? [],
            newopenningHoursException
        ]);
       
    };

    const addOpenningHoursException = async (newOpenningHoursException : OpenningHoursException) => {
        newOpenningHoursException.uid = uuidv4();
        setOpenningHoursException([
            ...openningHoursException ?? [],
            newOpenningHoursException
        ]);
    };

    const removeOpenningHoursException = async (newOpenningHoursException : OpenningHoursException) => {
        let dataOpenningHoursException = openningHoursException?.filter((openningHoursException)=>(openningHoursException !== newOpenningHoursException))
        setOpenningHoursException(dataOpenningHoursException);
    };
    
    return ([
                <form className={style.uiform} ref={formRefHours}>
                    <h2  className={styleModal.subtitle}>Les informations générales :</h2>
                    <div className={style.checkContenair}>
                    <FormControl>
                        <CheckboxOnly 
                            Objet={universData}
                            legend="Rattacher un ou plusieurs univers"
                            setCheckedObject={setCheckedUniversHours}
                            checkedObject={checkedUniversHours}
                            errorMessages={errorMessages}
                            required={false} 
                            customEvent={handleChangUnivers}/>
                    </FormControl>
                    <FormControl >
                        <Autocomplete
                            title={salepointLabel.join(', ')}
                            multiple
                            className={style.autocomplete}
                            limitTags={2}
                            options={salepointData}
                            disableCloseOnSelect
                            getOptionLabel={(salepoint :Salepoint) => salepoint.name}
                            onChange={handleChangSalepoint}
                            renderOption={(props, salepoint, { selected = salepointLabel.indexOf(salepoint.name) > -1 }) => {
                                const { ...optionProps } = props;
                                return (
                                <li  {...optionProps}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        value={salepoint.name}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={salepointLabel.indexOf(salepoint.name) > -1 || selected}
                                        required={(salepointLabel.length === 0)}
                                        />
                                    {salepoint.name}
                                </li>
                                );
                            }}
                            value={salepointData.filter((salepoint:Salepoint)=>{
                                if(salepointLabel.indexOf(salepoint.name) > -1) {
                                    return salepoint 
                                 }
                            })}
                            renderInput={ params => {
                                const { InputProps, ...restParams } = params;
                                const { startAdornment, ...restInputProps } = InputProps;
                                return (
                                  <TextField
                                    required={(salepointLabel.length === 0)}
                                    name='salepoint'
                                    error={!!errorMessages.salepoint}
                                    { ...restParams }
                                    label="Concession(s)"
                                    InputProps={ {
                                      ...restInputProps,
                                      startAdornment: (
                                        <div className={style.scrollDiv}>
                                          {startAdornment}
                                        </div>
                                      ),
                                    } }
                                  />
                                );
                              } }
                            />
                        <FormHelperText className='error'>{errorMessages.salepoint}</FormHelperText>
                    </FormControl>
                    <div className={styleOpenningHours.list}>
                        <ul>
                            {
                                (openningHoursException && openningHoursException.length > 0) ? 
                                    openningHoursException.map((openningHoursExceptionItem:OpenningHoursException, key:number) => {
                                        let firstOfDay = false
                                        if(openningHoursException[key-1] === undefined || openningHoursException[key-1].date !== openningHoursExceptionItem.date)
                                            firstOfDay = true
                                        return  <OpenningHoursExeption key={key}
                                                                    openningHoursException={openningHoursExceptionItem} 
                                                                    firstOfDay={firstOfDay}
                                                                    menuItemList={menuItemList}
                                                                    handleFormHoursExceptionChange={handleFormHoursExceptionChange}
                                                                    addOpenningHoursException={addOpenningHoursException}
                                                                    removeOpenningHoursException={removeOpenningHoursException}/>
                                    })
                                :
                                    ""
                            }
                        </ul>
                    </div>
                    <Button className={styleOpenningHours.button_fullwith} onClick={onClickHandlerAdd}>
                        <SpeedDialIcon/> Ajouter un horaire exceptionnels
                    </Button>
                    <div className={style.errorMessage}>{errorMessages["openningHoursException"] ?? ""}</div>
                    </div>
                </form>
           ]
    )
}
export default salepointPopupChildrens;