
import myJraLogo from '../../assets/img/my_Jra-logo.png';
import style from './loader.module.scss';

interface LoaderProps {
    position?: string;
  }
  
export const Loader: React.FC<LoaderProps> = ({ position }) => {
    return (
        <div id={style.myJraLoader} className={(position == "form" ? style.formLoader : "")}>
            <div className={style.content}>
                <div className={style.image}>
                    <img src={myJraLogo} alt="logo" />
                </div>
                <div className={style.wrapper}>
                    <div className={style.satWrap}>
                        <div className={style.sat}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}