import { DataGrid, GridCallbackDetails, GridColDef, GridRowParams, MuiEvent, GridToolbar, frFR, GridFilterModel, GridSortModel, GridLogicOperator } from '@mui/x-data-grid'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import { LinearProgress, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@mui/material';
import './Table.scss'
import TablePagination from '@mui/material/TablePagination';
import ClearIcon from '@mui/icons-material/Clear';
import * as locales from '@mui/material/locale';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';

interface TableChartProps {
  callBackFunctionGetRowData: (paginationModel: {
      page: number;
      pageSize: number;
  }, sort: {}, filter: {}) => Promise<void>
  dataRow: any[] | undefined;
  onRowClick: (params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => void;
  onCardClick: (salepointUid: string) => void;
  handleDeleteRow: (itemId: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  columns: GridColDef<any>[],
  loading :boolean,
  max: number
  searchFields: string[];
  disableRowSelectionOnClick? : boolean
}

export interface rowProps {
  rowCount:number;
  row:any[];
}

type SupportedLocales = keyof typeof locales;

function TableChart({ callBackFunctionGetRowData, dataRow, columns, onCardClick, onRowClick, handleDeleteRow, loading, max, searchFields, disableRowSelectionOnClick =false }: TableChartProps) {
  
    const [locale, setLocale] = React.useState<SupportedLocales>('frFR');
    const theme = useTheme();
    const themeWithLocale = React.useMemo(
      () => createTheme(theme, locales[locale]),
      [locale, theme],
    );

    const [rows, setRows] = useState<any>([]);
    const [rowsCount, setRowsCount] = useState<number>(0);
    const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
    });

    const [filterModelOptions, setFilterModeOptions] = useState({});
    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
      setFilterModeOptions({ filterModel: { ...filterModel } });
    }, []);

    const [sortModelOptions, setSortModelOptions] = useState({});
    const onSortChange = useCallback((sortModel: GridSortModel) => {
        setSortModelOptions({ sortModel: { ...sortModel } });
    }, []);

    useEffect(() => {
      (async () => { 
            await callBackFunctionGetRowData(paginationModel, sortModelOptions, filterModelOptions);
        })();
    }, [paginationModel, sortModelOptions, filterModelOptions]);

    useEffect(() => {
    if(dataRow){
        setRowsCount(max)
        setRows(dataRow);
    }
    }, [dataRow]);

    const [searchInput, setSearchInput] = useState("");
    const isMobile = useMediaQuery('(max-width: 600px)');
    const formRefsearch = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;


    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
        let filterModel :GridFilterModel = {
              "items": searchFields.map((field, index) => ({
                field,
                operator: 'contains',
                id: index,
                value: e.target.value,
              })),
              "logicOperator": GridLogicOperator.Or,
              "quickFilterValues": [],
              "quickFilterLogicOperator": GridLogicOperator.And,
        };
        setFilterModeOptions({ filterModel: { ...filterModel } });
    };

    const handleClear  = (e: React.MouseEvent<HTMLButtonElement>) => {
        setSearchInput("");
        let filterModel :GridFilterModel = {
          "items": searchFields.map((field, index) => ({
            field,
            operator: 'contains',
            id: index,
            value: '',
          })),
          "logicOperator": GridLogicOperator.Or,
          "quickFilterValues": [],
          "quickFilterLogicOperator": GridLogicOperator.And,
    };
        setFilterModeOptions({ filterModel: { ...filterModel } });
    };



    const truncateText = (text: string | string[], maxLength: number): string => {
        if (!text) {
            return '';
        }  
        const str = Array.isArray(text) ? text.join(', ') : text;
        const stringValue = String(str);
        if (str.length <= maxLength) {
            return str;
        } else {
            return stringValue.substring(0, maxLength) + '...';
        }
    };

    const TableRowsLoader = ( { rowsNum } : { rowsNum: number;}) => {
    return  <TableContainer component={Paper}>
                <Table>
                <TableHead>
                    <TableRow>
                    {columns.map((col, index) => (
                        <TableCell>{col.headerName}</TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[...Array(rowsNum)].map((row, index) => (
                    <TableRow key={index}>
                        {columns.map((col, index) => (
                        <TableCell component="th" scope="row">
                            <Skeleton animation="wave" variant="text" />
                        </TableCell>
                        ))}
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
    };

  return (
    <>
    {loading ? <LinearProgress /> : "" }
        {isMobile ? (
            <div>
                <TextField
                    label="Rechercher"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    value={searchInput}
                    InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                                {searchInput ? (
                                    <IconButton onClick={handleClear}>
                                        <ClearIcon />
                                    </IconButton>
                                ) : (
                                    <SearchIcon />
                                )}
                            </InputAdornment>
                        ),
                    }}
                    ref={formRefsearch}
                    sx={{ zIndex: 0 }}
                />
                <ThemeProvider theme={themeWithLocale}>
                  <TablePagination
                      component="div"
                      count={rowsCount}
                      page={paginationModel.page}
                      onPageChange={(event, newPage) => 
                          setPaginationModel({ ...paginationModel, page: newPage })
                      }
                      rowsPerPage={paginationModel.pageSize}
                      onRowsPerPageChange={(event) => setPaginationModel({ ...paginationModel, pageSize: parseInt(event.target.value, 10), page: 0 })}
                      
                  />
                </ThemeProvider>
                {dataRow && dataRow.map((item: any) => (
                  <Card key={item.id} variant="outlined" sx={{ marginBlock: '12px', position: 'relative' }}>
                    <CardContent>
                      {Object.keys(item).map((key) => (                        
                        key !== "id" ? (  
                          (key === "statut") ?
                            <ListItem key={key} className="card-item">
                              <span>{columns.find((col) => col.field === key)?.headerName || key}:&nbsp;{<Chip label={item[key].statusText} color={item[key].chipColor} variant="outlined" sx={{width: '90px'}} />}</span>
                            </ListItem>  
                           :                          
                            <ListItem key={key} className="card-item">
                              <span>
                                {columns.find((col) => col.field === key)?.headerName || key}:&nbsp;
                                {key === "concession" ? truncateText(item[key], 50) : truncateText(item[key], 50)}
                              </span>
                            </ListItem>
                        ) : ""

                      ))}
                      <div className='editdelete'>
                      <IconButton type="submit" onClick={(e) => { e.stopPropagation(); onCardClick(item.id); }}>
                        <EditIcon fontSize='small' color="primary" />
                      </IconButton>
                      <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteRow(item.id, e); }}>
                        <DeleteIcon fontSize='small' color="primary" />
                      </IconButton>
                      </div>
                    </CardContent>
                  </Card>
                ))}
            </div>
        ) : (
        (dataRow) ?
        <DataGrid
          columns={columns}
          pagination={true}
          
          sortingMode="server"
          filterMode="server"
          paginationMode="server"
          
          onPaginationModelChange={setPaginationModel}
          onFilterModelChange={onFilterChange}
          onSortModelChange={onSortChange}

          sx={{ zIndex: '0' }}
          rowHeight={40}
          rows={rows}
          rowCount={rowsCount}
          getRowId={(row) => row.id}
          onRowClick={onRowClick}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          slots={{
            toolbar: GridToolbar,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          disableRowSelectionOnClick={disableRowSelectionOnClick}
        />
        :
        <TableRowsLoader rowsNum={20}/>
      )}
    </>
  );
}

export default TableChart;