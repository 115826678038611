import React, { useState, useEffect } from "react";

import style from '../law.module.scss';
import { App } from '../../../../services/object/App';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import styled from "@emotion/styled";
import { Video } from "../../../../services/object/Video";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { Role } from "../../../../services/object/Role";
import uuidv4 from "../../../../services/tools/uuid";

interface FormVideoProps {
  errorMessages: Record<string, string>,
  app: App | undefined,
  TOvideo : Video[]
  ToRole : Role[]
  setTOvideo: React.Dispatch<React.SetStateAction<Video[]>>
  handleOpenTinyModalApp: (ToRole: Role[], TOvideo: Video[]) => void
  arrayRoleLabel: string[]
  onVideoChange: (file: File, videoUid: string, title: string) => Promise<void>
}
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FormVideo = ({
  errorMessages,
  TOvideo,
  ToRole,
  setTOvideo,
  onVideoChange,
  handleOpenTinyModalApp
}: FormVideoProps) => {

  const [videoTitle, setVideoTitle] = useState<string[]>([]);
  const [videoSupport, setVideoSupport] = useState<File[]>([]);
  const [videoNumber, setVideoNumber] = useState<number>(0);

  useEffect(() => {
    if (TOvideo.length) {
      // setVideoSupport(app.TMailSupport?.split('|') ?? []);
      setVideoNumber(TOvideo.length ?? 0)
      setVideoTitle(TOvideo.map((video :Video) => (video.title)))
    }
  }, [TOvideo]);

  useEffect(() => {
    console.log(videoSupport)
  }, [videoSupport]);
  

  const handleVideoChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, files } = event.target;
    if(files){
      let index = parseInt(name.split('_')[1])
      let newVideoSupport = videoSupport;
      newVideoSupport[index] = files[0];
      console.log(newVideoSupport)
      setVideoSupport(newVideoSupport)
      onVideoChange(files[0], TOvideo[index].uid, videoTitle[index] ?? uuidv4())
    }
  };

  const addVideo = () => {
    setVideoNumber(videoNumber+1)
    let newToVideo = TOvideo
    newToVideo.push({uid: uuidv4(), url: "", title:""})
    setTOvideo(newToVideo)
  }

  const onDelete = (index:number) => {
    let newToVideo = TOvideo;
    newToVideo.splice(index, 1);
    setTOvideo(newToVideo)
    setVideoNumber(videoNumber-1)
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let index = parseInt(name.split('_')[1])
    let newVideoSupport = videoTitle;
    newVideoSupport[index] = value;
    setVideoTitle(newVideoSupport)
  };

  let allVideo = [];
  for (let index = 0; index < videoNumber; index++) {
    console.log(videoTitle[index])
    allVideo.push(<div className={style.videoContainer}>
                    <TextField  fullWidth 
                                label="Titre de la vidéo"
                                value={videoTitle[index]}
                                size="medium"
                                name={"mailSupport_"+index}
                                onBlur={handleBlur}
                                required
                                error={!!errorMessages.name}
                                helperText={errorMessages.name} 
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={(e) => (onDelete(index))}>
                                        <DeleteForeverIcon/>
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}/>
                    <video controls src={TOvideo[index]?.url}></video>
                    <div className={style.videoBtnContainer}>
                      <Button component="label"
                              role="contained"
                              variant="contained"
                              tabIndex={-1}
                              startIcon={<VideoFileIcon />}>
                        {(TOvideo[index]?.url) ? "Remplacer la vidéo" : "Importer une vidéo"} 
                        <VisuallyHiddenInput  type="file"
                                              name={"video_"+index}
                                              onChange={handleVideoChange} />
                      </Button>
                    </div>
                  </div>
                )
  }

  return (
    <div className={style.contentText}>
      {allVideo.map((elem) => (elem))}
      <div className={style.fullWidth}>
        {
          (TOvideo.length > 0) ? 
            <Button className='addbutton'
              variant="contained"
              onClick={(e)=>(handleOpenTinyModalApp(ToRole, TOvideo))}
              endIcon={<AdminPanelSettingsIcon />}
              title='Configurer les droits utilisateurs associés'>
              Configurer
            </Button>
          :
            ""
        }
        <Button className='addbutton'
          variant="contained"
          onClick={addVideo}
          endIcon={<AddIcon />}>
          Ajouter une vidéo tuto
        </Button>
      </div>
    </div>
  );
};

export default FormVideo;
